import React, { Component } from 'react';
import Form from "react-bootstrap/Form";

const Input = ({name, label, error, enabled, checked, onChange, ...rest}) => {
    
    return (  
        <div className="form-group">
            {/*<label htmlFor={name}>{label}</label>
             <input 
                {...rest}
                id={name}
                name={name}
                className="form-control"            
            /> */}
            <Form.Switch 
                {...rest}
                className="switch" 
                type="switch" 
                id={name}
                name={name} 
                label={label}
                checked={checked}
                value={checked}
                onChange={onChange}
                 />
            {error && <div className="p-1 alert alert-danger">{error}</div>}
        </div>
    );
}
 
export default Input;