import React, { Component } from "react";
import Ticker, { FinancialTicker, NewsTicker } from "nice-react-ticker";
import { withTranslation } from "react-i18next";
import Loading from "../common/components/loading/loading";
import { getSubscribedMarketPrices } from "../common/services/marketdata/marketPriceUtilities";
import { formatDate } from "../common/services/utilities";

class MarketDataTicker extends Component {
  state = {
    tickerData: [],
    isLoading: true,
  };

  async componentDidMount() {
    const { userAccountId } = this.props;

    const marketDate = formatDate(new Date());

    const tickerData = await getSubscribedMarketPrices(
      userAccountId,
      marketDate
    );

    this.setState({ tickerData: tickerData, isLoading: false });
  }

  render() {
    const { tickerData, isLoading } = this.state;
    const slideSpeed = 6 * tickerData.length;

    if (isLoading) return <Loading />;

    return (
      <Ticker slideSpeed={slideSpeed}>
        {tickerData.map((data, index) => (
          <FinancialTicker
            id={index}
            key={index}
            change={data.price - data.previousPrice >= 0 ? true : false}
            symbol={
              data.marketName +
              (data.qualityAttributeValues
                ? " " + data.qualityAttributeValues
                : "") +
              (data.termName == "Latest" ? "" : " (" + data.termName + ")")
            }
            lastPrice={
              data == null || data.price == null
                ? 0
                : "$" + data.price.toFixed(2)
            }
            percentage={
              (data.previousPrice
                ? (
                    ((data.price - data.previousPrice) / data.price) *
                    100
                  ).toFixed(2)
                : "- ") + "%"
            }
            currentPrice={
              data.previousPrice
                ? "$" + (data.price - data.previousPrice).toFixed(2)
                : ""
            } //This is actually the change
          />
        ))}
      </Ticker>
    );
  }
}

export default withTranslation(["marketdata"])(MarketDataTicker);
