import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getTermScheduleHistory } from "../../../common/services/markets/termScheduleService";
import { withTranslation } from "react-i18next";
import { conveyorBelt } from "fontawesome";

class TermScheduleHistory extends Component {
  state = {
    termScheduleHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { termScheduleId } = this.props;
    const termScheduleHistory = this.mapToViewModel(
      await getTermScheduleHistory(termScheduleId)
    );

    this.setState({
      termScheduleHistory: _.orderBy(termScheduleHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(termSchedules) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return termSchedules.map((termSchedule) => ({
      id: termSchedule.id,
      name: termSchedule.name,
      forwardReportingPeriodicity: termSchedule.forwardReportingPeriodicity,
      historicalReportingPeriodicity:
        termSchedule.historicalReportingPeriodicity,
      active: termSchedule.active.toString(),
      updatedByUserAccountId: termSchedule.createdByUserAccountId,
      updatedBy:
        termSchedule.updatedByUserAccountFirstName +
        " " +
        termSchedule.updatedByUserAccountLastName,
      updatedDate: new Date(termSchedule.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(termSchedule.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: termSchedule.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("TermScheduleName") },
    {
      path: "forwardReportingPeriodicity",
      label: this.props.t("ForwardReportingPeriodicity"),
    },
    {
      path: "historicalReportingPeriodicity",
      label: this.props.t("HistoricalReportingPeriodicity"),
    },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { termScheduleHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, termScheduleId } = this.props;

    return (
      <div className="history-table-container">
        {t("TermScheduleID")} {termScheduleId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={termScheduleHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(TermScheduleHistory);
