import Keycloak from "keycloak-js";
import HttpService from "../app/httpService";

const keycloak = new Keycloak({
 url: process.env.REACT_APP_KEYCLOAK_HOST,
 realm: process.env.REACT_APP_KEYCLOAK_REALM,
 clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
 enableBearerInterceptor: true,
});

export default keycloak;