import React, { useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';

const LifecycleChart = React.memo(({ data, title, uniqueStatusValues, colorScheme, vintages }) => {
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);

    // Memoize the render function to avoid unnecessary re-renders
    const renderChart = useCallback(() => {

        // Setup the chart dimensions
        const margin = { top: 40, right: 140, bottom: 40, left: 70 };
        const containerWidth = chartRef.current.offsetWidth;
        const containerHeight = 300;//chartRef.current.offsetHeight;
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;

        // Clear any existing chart
        d3.select(chartRef.current).select("svg").remove();

        // Create SVG container
        const svg = d3.select(chartRef.current)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Process the data
        const dataMap = vintages.reduce((acc, vintage) => {
            // Initialize each vintage with zero quantities for each status
            acc[vintage] = { Vintage: vintage };
            uniqueStatusValues.forEach(status => acc[vintage][status] = 0);
            return acc;
        }, {});

        // Populate dataMap with actual data
        data.forEach(item => {
            if (dataMap[item.Vintage]) {
                dataMap[item.Vintage][item.Status] += item.Quantity;
            }
        });

        const processedData = Object.values(dataMap);

        // Create stack generator
        const stack = d3.stack()
            .keys(uniqueStatusValues)
            .value((d, key) => d[key]);

        const stackedData = stack(processedData);

        // Create scales
        const x = d3.scaleBand()
            .domain(vintages)
            .range([0, width])
            .padding(0.2);

        const y = d3.scaleLinear()
            .domain([0, d3.max(stackedData, d => d3.max(d, d => d[1]))])
            .nice()
            .range([height, 0]);

        const color = d3.scaleOrdinal()
            .domain(uniqueStatusValues)
            .range(colorScheme);

        // Add X axis
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x).tickSize(0))
            .selectAll("text")
            .attr("transform", "rotate(-45)")
            .style("text-anchor", "end")
            .style("fill", "grey");  // Set label color to white

        // Add Y axis
        svg.append("g")
            .call(d3.axisLeft(y))
            .selectAll("text")
            .style("fill", "white");  // Set label color to white

        // Create a tooltip element
        const tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("background", "rgba(0, 0, 0, 0.7)")
            .style("border-radius", "5px")
            .style("color", "white")
            .style("pointer-events", "none")
            .style("opacity", 0);

        tooltipRef.current = tooltip;            


        // Add stacks
        svg.selectAll(".layer")
            .data(stackedData)
            .enter()
            .append("g")
            .attr("class", "layer")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("x", d => x(d.data.Vintage))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width", x.bandwidth())
            .on("mouseover", function(event, d) {
                // Show tooltip
                tooltip.transition().duration(200).style("opacity", 1);
                tooltip.style("padding", "5px");                
                tooltip.html(`
                    <strong>Vintage:</strong> ${d.data.Vintage}<br>
                    <strong>Status:</strong> ${this.parentNode.__data__.key}<br>
                    <strong>Quantity:</strong> ${d[1] - d[0]}
                `);
            })
            .on("mousemove", function(event) {
                // Move tooltip with the mouse
                tooltip
                    .style("left", (event.pageX + 10) + "px")
                    .style("top", (event.pageY - 28) + "px");
            })
            .on("mouseout", function() {
                // Hide tooltip
                tooltip.transition().duration(500).style("opacity", 0);
            });

        // Add legend
        const legend = svg.append("g")
            .attr("transform", `translate(${width + 20}, -20)`)
            .selectAll(".legend")
            .data(uniqueStatusValues)
            .enter().append("g")
            .attr("class", "legend")
            .attr("transform", (d, i) => `translate(0, ${i * 22})`);

        legend.append("rect")
            .attr("x", -18)
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", color);

        const wrapText = (text, width) => {
            text.each(function() {
                const textElement = d3.select(this);
                const words = textElement.text().split(/\s+/).reverse();
                const lineHeight = 1.1; // ems
                const x = textElement.attr("x");
                const y = textElement.attr("y");
                const dy = parseFloat(textElement.attr("dy")) - (lineHeight / 2) || 0;
                let lineNumber = 0;
                let line = [];
                let word;
                let tspan = textElement.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", `${dy}em`);

                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = textElement.append("tspan")
                            .attr("x", x)
                            .attr("y", y)
                            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
                            .text(word);
                    }
                }
            });
        };

        legend.append("text")
            .attr("x", 0)
            .attr("y", 9)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .style("fill", "white")  // Set legend text color to white
            .style("font-size", "9px")
            .text(d => d)
            .call(wrapText, 120);

        // Add chart title
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", -margin.top / 2)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            //.style("text-decoration", "underline")
            .style("fill", "white")  // Set title color to white
            .text(title);

    }, [data, title, uniqueStatusValues, colorScheme]);

    useEffect(() => {
        requestAnimationFrame(renderChart);

        return () => {
            // Remove the tooltip when the component unmounts
            if (tooltipRef.current) {
                tooltipRef.current.remove();
            }
        }; 
    }, [renderChart]);

    useEffect(() => {
        const handleResize = () => {
            renderChart();  // Re-render the chart on resize
        };

        const observer = new ResizeObserver(handleResize);
        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (chartRef.current) {
                observer.unobserve(chartRef.current);
            }
        };
    }, [renderChart]);

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
});

export default LifecycleChart;