import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Select from "../../common/components/form/select";
import { getSpotByTermSchedule} from "../../common/services/markets/termService";
import { pivotQualityAttributes} from "../../common/services/products/productUtilities";
import { SHA256 } from 'crypto-js';
import { joinArrays } from "../../common/services/utilities";
import Table from '../../common/components/table/table';
import _ from 'lodash';
import {aggregateMarks} from "../../common/services/marketdata/marketDataUtilities";

class AttributeSpreads extends Component {

    state = {
        qualityAttributes: [],
        qualityAttributeId: this.props.product.qualityAttributes[0].qualityAttributeId,
        marks: []
   };

    async componentDidMount() {
        const {marks, product, fromDate, toDate, termScheduleId} = this.props;
        const qualityAttributes = product.qualityAttributes.filter(x => x.isPriceDriver);
        const qualityAttributeId = qualityAttributes[0].qualityAttributeId;
   
        const terms = await getSpotByTermSchedule(termScheduleId, fromDate.toLocaleDateString('fr-CA'), toDate.toLocaleDateString('fr-CA'));
        const spotTerms = terms.map(x => x.termId+x.marketDate);

        const spotMarks = marks.filter(x => spotTerms.includes(x.termId+x.marketDate));
        const aggregatedMarks = aggregateMarks(spotMarks)

        this.setState({qualityAttributes:qualityAttributes, qualityAttributeId:qualityAttributeId, marks: aggregatedMarks })
    };

    handleQualityAttributeChange = (qualityAttributeId) => {      
        this.setState({qualityAttributeId: qualityAttributeId });
    };
  
    getTableData(qualityAttributeId)
    {      
        const {product, currencySymbol, pricePrecision} = this.props;
        const {marks} = this.state;
        const {qualityAttributeValues, qualityAttributes} = product;
        const qualityAttribute = qualityAttributes.find( x => x.qualityAttributeId === qualityAttributeId);

        //Set up the columns for the table:
        const columns = 
            [
                {path: "qualityAttributeValue", label:qualityAttribute && qualityAttribute.qualityAttributeName},
                ...qualityAttributeValues.filter(x => x.qualityAttributeId === qualityAttributeId).map( (attributeValue) => {
                    return { 
                        path: attributeValue.qualityAttributeValueId
                        , label: attributeValue.value
                        , content: data =>
                        <div style={{color: data[attributeValue.qualityAttributeValueId] < 0 ? "Red" : (data[attributeValue.qualityAttributeValueId] > 0 ? "Green" : "")}}>
                            {!isNaN(parseFloat(data[attributeValue.qualityAttributeValueId])) ? currencySymbol+ data[attributeValue.qualityAttributeValueId].toFixed(pricePrecision) : null }
                        </div>
                    }
                })
            ]

        //Get the cartesian product of the attributes
        const pivotedAttributes = pivotQualityAttributes(product);

        //Calculated and attach the qualityAttributeValueHash
        const attributeValuesWithHashes = pivotedAttributes.map(obj =>
        {
            // Extract and sort the values of the QualityAttributeValueId field
            const sortedQualityAttributeValues = obj.qualityAttributeValues
                .map(qualityAttributeValue => qualityAttributeValue.qualityAttributeValueId)  // Extract the values
                .filter(value => value !== undefined)  // Filter out undefined values
                .sort();  // Sort the values

                // Concatenate the sorted values
                const concatenatedValues = sortedQualityAttributeValues.join('');
                const qualityAttributeValueHash = SHA256(concatenatedValues).toString().toUpperCase();
                const qualityAttributeValue = obj[qualityAttributeId];
                const qualityAttributeValueFull = obj.qualityAttributeValues.find(x => x.qualityAttributeId === qualityAttributeId);
                const qualityAttributeValueId = qualityAttributeValueFull.qualityAttributeValueId;

                return {qualityAttributeValue, qualityAttributeValueId , qualityAttributeValueHash}
        });      

        const marksWithAttributeValues = joinArrays(marks,attributeValuesWithHashes,'qualityAttributeValueHash');

        const data = marksWithAttributeValues.reduce((acc, curr) => {
            if (!acc[curr.qualityAttributeValueId] || new Date(curr.marketDate) > new Date(acc[curr.qualityAttributeValueId].marketDate)) {
                acc[curr.qualityAttributeValueId] = curr;
            }
            return acc;
        }, {});
                

        // Create an array of keys from the data object
        const qualityAttributeValuesFiltered = qualityAttributeValues.filter(x => x.qualityAttributeId === qualityAttributeId).map(x => x.qualityAttributeValueId)

        // Create a matrixed array
        const tableData = [];

        // Iterate over the keys (qualityAttributeValues)
        qualityAttributeValuesFiltered.forEach(qualityAttributeValueId => {
            // Create an object for the current qualityAttributeValue

            const matrixedObject = {
                //qualityAttributeValue: data[qualityAttributeValue].qualityAttributeValue
                qualityAttributeValue: qualityAttributeValues.find(x => x.qualityAttributeValueId === qualityAttributeValueId).value
            };

            // Iterate over the keys again to calculate price differences
            qualityAttributeValuesFiltered.forEach(otherQualityAttributeValueId => {
                // Calculate the price difference and set it in the matrixed object
                const record = data[qualityAttributeValueId];
                const otherRecord = data[otherQualityAttributeValueId];
                const priceDifference = (otherRecord && record ? otherRecord.price ?? undefined : undefined) - (otherRecord && record ? record.price ?? undefined : undefined);
                matrixedObject[otherQualityAttributeValueId] = qualityAttributeValueId === otherQualityAttributeValueId ? NaN : priceDifference;
            });

            // Push the matrixed object into the matrixed array
            tableData.push(matrixedObject);
        });  

        return { columns: columns, data: data, tableData: tableData };
    }


    render() { 

        const {marks, pricePrecision, currencySymbol, product, t} = this.props;

        const { qualityAttributes, qualityAttributeId } = this.state;

        const {columns, data, tableData} = this.getTableData(qualityAttributeId);

        return (
            <div className="container-fluid" > 
                <div className="row">
                    <div className="col-md-12">
                        <Select
                            data-bs-theme="dark" 
                            idField = "qualityAttributeId"
                            name = "qualityAttribute"
                            optionField = "qualityAttributeName"
                            disabled={Object.keys(qualityAttributes).length < 2}
                            value = {qualityAttributeId}
                            label = {t("QualityAttribute")}
                            options={qualityAttributes}
                            onChange={e => this.handleQualityAttributeChange(e.currentTarget.value)}
                        />     
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Table style = {{margin: "0 auto"}} className="table-marks" columns={columns} sortColumn = "qualityAttributeValue" data={tableData} valueProperty="qualityAttributeValue"/>
                    </div>
                </div>
                 
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(AttributeSpreads)