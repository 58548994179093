import React, { useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, title, groupBy }) => {
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);

    // Memoize the render function to avoid unnecessary re-renders
    const renderChart = useCallback(() => {
        // Setup the chart dimensions
        const margin = { top: 40, right: 120, bottom: 40, left: 20 };
        const containerWidth = chartRef.current.offsetWidth;
        const containerHeight = 300; //chartRef.current.offsetHeight || 400;  // Default height if none provided
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;
        const radius = Math.min(width, height) / 2;

        // Clear any existing chart
        d3.select(chartRef.current).select("svg").remove();

        // Create SVG container
        const svg = d3.select(chartRef.current)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr("preserveAspectRatio", "xMinYMin meet")
            .append("g")
            .attr("transform", `translate(${Math.max(radius + margin.left, (width / 2) - (margin.right / 2))}, ${(height + margin.top + margin.bottom) / 2})`);

        // Process the data
        const dataMap = data.reduce((acc, item) => {
            const key = item[groupBy];
            if (!acc[key]) {
                acc[key] = 0;
            }
            acc[key] += item.Quantity;
            return acc;
        }, {});

        // Convert dataMap to an array and sort it
        let pieData = Object.entries(dataMap).map(([key, value]) => ({ key, value }));
        pieData.sort((a, b) => b.value - a.value);

        // Handle "All Others" category
        const topNData = pieData.slice(0, 10);
        const others = pieData.slice(10).reduce((acc, cur) => acc + cur.value, 0);
        if (others > 0) {
            topNData.push({ key: 'All Others', value: others });
        }

        // Create pie and arc generators
        const pie = d3.pie()
            .value(d => d.value)
            .sort(null);

        const arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        const color = d3.scaleOrdinal()
            .domain(topNData.map(d => d.key))
            .range(['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', 'yellow', '#bcbd22', '#17becf','grey']);

        // Create a tooltip element
        const tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("background", "rgba(0, 0, 0, 0.7)")
            .style("border-radius", "5px")
            .style("color", "white")
            .style("pointer-events", "none")
            .style("opacity", 0);

        tooltipRef.current = tooltip;            

        // Draw pie slices
        const arcs = svg.selectAll(".arc")
            .data(pie(topNData))
            .enter()
            .append("g")
            .attr("class", "arc");

        arcs.append("path")
            .attr("d", arc)
            .attr("fill", d => color(d.data.key))
            .on("mouseover", function(event, d) {
                tooltip.transition().duration(200).style("opacity", 1);
                tooltip.style("padding", "5px");                
                tooltip.html(`
                    <strong>${d.data.key}</strong> <br>
                    <strong>Quantity:</strong> ${d.data.value.toLocaleString()}
                `);
            })
            .on("mousemove", function(event) {
                tooltip
                    .style("left", (event.pageX + 10) + "px")
                    .style("top", (event.pageY - 28) + "px");
            })
            .on("mouseout", function() {
                tooltip.transition().duration(500).style("opacity", 0);
            });                    



        // Add chart title
        svg.append("text")
            .attr("x", 0)
            .attr("y", -height / 2 - margin.top / 2)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("fill", "white")
            .text(title);

        // Add legend
        const legend = svg.append("g")
            .attr("transform", `translate(${radius + margin.left}, ${-height/2})`)  // Move legend to the right of the chart
            .selectAll(".legend")
            .data(topNData)
            .enter().append("g")
            .attr("class", "legend")
            .attr("transform", (d, i) => `translate(0, ${i * 22})`);

        legend.append("rect")
            .attr("x", 0)  // Ensure rectangles start at x=0 in the legend group
            .attr("width", 18)
            .attr("height", 18)
            .style("fill", d => color(d.key))
            

        const wrapText = (text, width) => {
            text.each(function() {
                const textElement = d3.select(this);
                const words = textElement.text().split(/\s+/).reverse();
                const lineHeight = 1.1; // ems
                const x = textElement.attr("x");
                const y = textElement.attr("y");
                const dy = parseFloat(textElement.attr("dy")) - (lineHeight / 2) || 0;
                let lineNumber = 0;
                let line = [];
                let word;
                let tspan = textElement.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", `${dy}em`);

                //Add a line
                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width ) {
                        line.pop();
                        // if (lineNumber === 1)
                        // {                                
                        //     line.push("...");
                        // }   
                        if (lineNumber < 1) //Limit to two lines
                        {                                                 
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = textElement.append("tspan")
                                .attr("x", x)
                                .attr("y", y)
                                .attr("dy", `${++lineNumber * lineHeight + dy}em`)
                                .text(word);
                        }
                    }
                }
            });
        };

        legend.append("text")
            .attr("x", 18)
            .attr("y", 9)
            .attr("dy", ".35em")
            .style("text-anchor", "start")
            .style("fill", "white")
            .style("font-size", "9px")
            .text(d => d.key)
            .call(wrapText, 180);

    }, [data, title, groupBy]);

    useEffect(() => {
        requestAnimationFrame(renderChart);

        return () => {
            // Remove the tooltip when the component unmounts
            if (tooltipRef.current) {
                tooltipRef.current.remove();
            }
        };    
    }, [renderChart]);

    useEffect(() => {
        const handleResize = () => {
            renderChart();  // Re-render the chart on resize
        };

        const observer = new ResizeObserver(handleResize);
        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (chartRef.current) {
                observer.unobserve(chartRef.current);
            }
        };
    }, [renderChart]);

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default PieChart;