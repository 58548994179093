import React, { Component } from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { Tabs, Tab } from "react-bootstrap";
import {
  getCommodityClass,
  addCommodityClass,
  updateCommodityClass,
} from "../../../common/services/products/commodityClassService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import CommodityClassHistory from "./commodityClassHistory";
import { withTranslation } from "react-i18next";

class CommodityClass extends Form {
  state = {
    data: {
      commodityClassId: "",
      name: "",
      description: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    commodityClassId: Joi.string(),
    name: Joi.string().required().label(this.props.t("CommodityClassName")),
    description: Joi.string().label(this.props.t("CommodityClassDescription")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { commodityClassId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var commodityClass = {};

    if (!isNew) {
      commodityClass = await getCommodityClass(commodityClassId);
      commodityClass.eventId = uuid();
    } //New User Add
    else {
      commodityClass = {
        commodityClassId: commodityClassId,
        name: "",
        description: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({
      data: this.mapToViewModel(commodityClass),
      isLoading: false,
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(commodityClass.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(commodityClass.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });
    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(commodityClass) {
    return {
      commodityClassId: commodityClass.commodityClassId,
      name: commodityClass.name,
      description: commodityClass.description,
      active: commodityClass.active,
      eventId: commodityClass.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        const response = await updateCommodityClass(this.state.data);
        toast.success(t("CommodityClassUpdated"));
      } else {
        const response = await addCommodityClass(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.commodityClassId, this.state.data.name);
        toast.success(t("CommodityClassAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(this.state.data);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { commodityClass, isLoading, isNew, createdDate, updatedDate } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, commodityClassId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("CommodityClassName"))}
              {this.renderInput("description", t("CommodityClassDescription"))}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <CommodityClassHistory commodityClassId={commodityClassId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["products"])(CommodityClass);
