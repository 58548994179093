import React, { useRef, useEffect, useCallback } from 'react';
import * as d3 from 'd3';

const TradesChart = ({ data, title, startDate, endDate }) => {
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);

    const renderChart = useCallback(() => {
        // Setup dimensions and margins
        const margin = { top: 40, right: 20, bottom: 70, left: 60 }; // Increased bottom margin for rotated labels
        const containerWidth = chartRef.current.offsetWidth;
        const containerHeight = 400; // Default height if not specified
        const width = containerWidth - margin.left - margin.right;
        const height = containerHeight - margin.top - margin.bottom;
    
        // Clear previous chart
        d3.select(chartRef.current).select("svg").remove();
    
        // Create SVG container
        const svg = d3.select(chartRef.current)
            .append("svg")
            .attr("width", containerWidth)
            .attr("height", containerHeight)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
    
        // Process data
        const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");
        const formatDate = d3.timeFormat("%Y-%m-%d");
    
        // Extract all unique dates from your data
        const allDates = data.map(d => d.TransactionDate.split('T')[0]);
        const uniqueDates = Array.from(new Set(allDates));
    
        // Generate an array of all days between startDate and endDate
        const dateRange = d3.timeDay.range(new Date(startDate), d3.timeDay.offset(new Date(endDate), 1));
    
        // Create data with zero quantity for each day in the date range
        const dataByDay = dateRange.map(date => ({
            date: date, // Use the date object directly
            quantity: data.filter(d => d.TransactionDate.split('T')[0] === formatDate(date))
                .reduce((acc, curr) => acc + curr.Quantity, 0)
        }));
    
        // Set up scales
        const x = d3.scaleBand()
            .domain(dateRange.map(d => formatDate(d))) // Ensure domain matches the tick format
            .range([0, width])
            .padding(0.1); // Adjust padding as needed for spacing between bars
    
        const y = d3.scaleLinear()
            .domain([0, Math.max(1,d3.max(dataByDay, d => d.quantity))]).nice()
            .range([height, 0]);
   
        // Draw axes
        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x)
                .tickValues(dateRange.map(d => formatDate(d))) // Match tick values to the formatted domain
                .tickFormat(d => {
                    // Format tick labels as desired (e.g., 'Jun 17')
                    const date = d3.timeParse("%Y-%m-%d")(d);
                    return d3.timeFormat('%b %d')(date);
                }))
            .selectAll("text")
            .attr("transform", "rotate(-45)") // Rotate labels 45 degrees
            .style("text-anchor", "end") // Align text to the end
            .style("font-size", "10px"); // Adjust font size for better fit
    
        svg.append("g")
            .call(d3.axisLeft(y));

        // Create a tooltip element
        const tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("background", "rgba(0, 0, 0, 0.7)")
            .style("border-radius", "5px")
            .style("color", "white")
            .style("pointer-events", "none")
            .style("opacity", 0);           

        tooltipRef.current = tooltip;   

        // Draw bars with spacing
        svg.selectAll(".bar")
            .data(dataByDay)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", d => x(formatDate(d.date)))
            .attr("y", d => y(d.quantity))
            .attr("width", x.bandwidth())
            .attr("height", d => height - y(d.quantity))
            .attr("fill", "steelblue")
            .on("mouseover", function(event, d) {
                // Show tooltip
                tooltip.transition().duration(200).style("opacity", 1);
                tooltip.style("padding", "5px");                
                tooltip.html(`
                    <strong>Date:</strong> ${ d3.timeFormat('%b %d')(d.date)}<br>
                    <strong>Quantity:</strong> ${d3.format(",")(d.quantity)}
                `);
            })
            .on("mousemove", function(event) {
                // Move tooltip with the mouse
                tooltip
                    .style("left", (event.pageX + 10) + "px")
                    .style("top", (event.pageY - 28) + "px");
            })
            .on("mouseout", function() {
                // Hide tooltip
                tooltip.transition().duration(500).style("opacity", 0);
            });            ;
    
        // Add chart title
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", -10)
            .attr("text-anchor", "middle")
            .style("font-size", "16px")
            .style("font-weight", "bold")
            .style("fill", "white")
            .text(title);
    
        // Add x-axis label
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height + margin.bottom - 10)
            .attr("text-anchor", "middle")
            .style("font-size", "12px")
            .style("fill", "white")
            .text("Date");
    
        // // Add y-axis label
        // svg.append("text")
        //     .attr("transform", "rotate(-90)")
        //     .attr("y", -margin.left + 20)
        //     .attr("x", -height / 2)
        //     .attr("text-anchor", "middle")
        //     .style("font-size", "12px")
        //     .style("fill", "white")
        //     .text("Quantity");
    
    }, [data, title, startDate, endDate]);

    useEffect(() => {
        renderChart();

        return () => {
            // Remove the tooltip when the component unmounts
            if (tooltipRef.current) {
                tooltipRef.current.remove();
            }
        };   
    }, [renderChart]);

    useEffect(() => {
        const handleResize = () => {
            renderChart(); // Re-render the chart on resize
        };

        const observer = new ResizeObserver(handleResize);
        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (chartRef.current) {
                observer.unobserve(chartRef.current);
            }
        };
    }, [renderChart]);

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default TradesChart;
