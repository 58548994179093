import React, { Component } from "react";
import _ from "lodash";
import Table from "../../common/components/table/table";
import Loading from "../../common/components/loading/loading";
import { pivotQualityAttributes } from "../../common/services/products/productUtilities";
import { getOrganization } from "../../common/services/auth/organizationService";
import {
  copyPriorMarks,
  generateAlbertaEnviromentalSettlements,
} from "../../common/services/marketdata/markGenerationService";
import { joinArrays, dateStringIsToday } from "../../common/services/utilities";
import MarkCell from "./markCell";
import { SHA256 } from "crypto-js";
import { withTranslation } from "react-i18next";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CommodityType } from "../../common/enumerations/enums";

class MarksTable extends Component {
  state = {
    marks: [],
    columns: [],
    organizationNameInScope: null,
    qualityAttributeValuesByHash: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    await this.initialize(this.props);
  }

  async componentDidUpdate(previousProps) {
    if (
      previousProps.marketDate !== this.props.marketDate ||
      previousProps.priceBasisId !== this.props.priceBasisId ||
      previousProps.currencyId !== this.props.currencyId ||
      previousProps.uomId !== this.props.uomId ||
      previousProps.organizationId !== this.props.organizationId ||
      !_.isEqual(previousProps.marks, this.props.marks) ||
      !_.isEqual(previousProps.terms, this.props.terms)
    ) {
      await this.initialize(this.props);
    }
  }

  async initialize(passedProps) {
    const {
      organizationId,
      product,
      marks,
      terms,
      productId,
      marketId,
      marketDate,
      termScheduleId,
      priceBasisId,
      uomId,
      currencyId,
    } = passedProps;

    const columns = this.mapToColumns(product, terms);
    const pivotedData = pivotQualityAttributes(product);

    const joinedData = this.joinMarks(pivotedData, marks);

    const organization = await getOrganization(organizationId);

    this.setState({
      marks: joinedData,
      columns: columns,
      qualityAttributeValuesByHash: joinedData.reduce((qavByHash, jd) => {
        qavByHash[jd.qualityAttributeValueHash] = jd.qualityAttributeValues;
        return qavByHash;
      }, {}),
      organizationNameInScope: organization.name,
      isLoading: false,
    });
  }

  mapToColumns(products, terms) {
    const {
      organizationId,
      marketId,
      marketDate,
      priceBasisId,
      currencyId,
      uomId,
      pricePrecision,
      volumePrecision,
      currencySymbol,
      allowEdit,
      allowOverrideMarks,
    } = this.props;

    var columns = [
      { path: "shortName", label: this.props.t("MarketDataProduct") }, //Set the product name as the first column
      ...products.qualityAttributes
        .filter((qualityAttribute) => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
        .map(
          (
            qualityAttribute //Add one new column for each qualityAttribute
          ) => ({
            path: qualityAttribute.qualityAttributeId,
            label: qualityAttribute.qualityAttributeName,
          })
        ),
      ...terms
        .sort((a, b) => (new Date(a.endDate) > new Date(b.endDate) ? 1 : -1))
        .map(
          (
            term //Add one new column for each term
          ) => ({
            path: term.termId,
            label: term.name,
            className: "clickable",
            content: (data) => (
              <MarkCell
                allowEdit={
                  data[term.termId] &&
                  data[term.termId].markState === "Approved"
                    ? false
                    : allowEdit
                }
                allowOverrideMarks={allowOverrideMarks}
                termId={term.termId}
                termName={term.name}
                organizationId={organizationId}
                marketId={marketId}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                currencySymbol={currencySymbol}
                marketDate={marketDate}
                mark={
                  data[term.termId] ?? {
                    markId: uuid(),
                    eventId: uuid(),
                    qualityAttributeValueHash: data.qualityAttributeValueHash,
                    active: true,
                  }
                }
                qualityAttributeValues={data.qualityAttributeValues}
                shortName={data["shortName"]}
                isNew={data[term.termId] ? false : true}
                submitCallbackFunction={this.props.submitCallbackFunction}
                handleDelete={this.props.handleDelete}
              />
            ),
          })
        ),
    ];

    return columns;
  }

  joinMarks(pivotedData, marks) {
    const dataWithHashes = pivotedData.map((obj) => {
      // Extract and sort the values of the QualityAttributeValueId field
      const sortedQualityAttributeValues = obj.qualityAttributeValues
        .map(
          (qualityAttributeValue) =>
            qualityAttributeValue.qualityAttributeValueId
        ) // Extract the values
        .filter((value) => value !== undefined) // Filter out undefined values
        .sort(); // Sort the values

      // Concatenate the sorted values
      const concatenatedValues = sortedQualityAttributeValues.join("");
      const qualityAttributeValueHash = SHA256(concatenatedValues)
        .toString()
        .toUpperCase();

      return { ...obj, qualityAttributeValueHash };
    });

    //Pivot the marks
    const groupedByQualityAttributeValueHash = marks.reduce((result, obj) => {
      const { qualityAttributeValueHash, termId, ...rest } = obj;

      if (!result[qualityAttributeValueHash]) {
        result[qualityAttributeValueHash] = {};
      }

      //result[qualityAttributeValueHash][termId] = { termId, ...rest };
      //result[qualityAttributeValueHash] = (...result[qualityAttributeValueHash], [termId]: { termId, ...rest });
      result[qualityAttributeValueHash][termId] = {
        ...rest,
        qualityAttributeValueHash: qualityAttributeValueHash,
      };

      return result;
    }, {});

    const pivotedMarks = Object.entries(groupedByQualityAttributeValueHash).map(
      ([qualityAttributeValueHash, terms]) => ({
        qualityAttributeValueHash,
        ...terms,
        //terms: Object.values(terms),
      })
    );

    const joinedData = joinArrays(
      dataWithHashes,
      pivotedMarks,
      "qualityAttributeValueHash"
    );

    return joinedData;
  }

  async generateMarks(commodityType) {
    const { qualityAttributeValuesByHash } = this.state;
    const {
      t,
      marketId,
      organizationId,
      termScheduleId,
      marketDate,
      terms,
      loadMarks,
      updateGeneratedMarks,
    } = this.props;

    try {
      let marksGenerated = [];
      switch (commodityType) {
        case CommodityType.None:
          marksGenerated = await copyPriorMarks({
            MarketId: marketId,
            OrganizationId: organizationId,
            TermScheduleId: termScheduleId,
            MarketDate: marketDate,
            Terms: terms.map((t) => t.termId),
            QualityAttributeValuesByHash: qualityAttributeValuesByHash,
            EventId: uuid(),
          });
          break;

        case CommodityType.AlbertaEnviromentals:
          marksGenerated = await generateAlbertaEnviromentalSettlements({
            MarketId: marketId,
            OrganizationId: organizationId,
            TermScheduleId: termScheduleId,
            MarketDate: marketDate,
            Terms: terms.map((t) => t.termId),
            QualityAttributeValuesByHash: qualityAttributeValuesByHash,
            EventId: uuid(),
          });
          break;

        default:
          break;
      }

      // Update Mark state
      updateGeneratedMarks(marksGenerated);

      // Refresh marks screen
      await loadMarks(marketId, marketDate, organizationId);

      toast.success(
        t("GeneratedNMarks", { numberOfMarks: marksGenerated.length })
      );
    } catch (ex) {
      console.log(t("ErrorGeneratingSettlements"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(
                t("ErrorGeneratingSettlements") + " " + key + ": " + error
              );
            });
          });
        } else {
          toast.error(t("ErrorGeneratingSettlements") + " " + ex.response.data);
        }

        toast.error(ex.response.data.errors.title);
      }
    }
  }

  render() {
    const { sortColumn, isLoading, columns, marks } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowGenerateMarks, allowCopyMarks, marketDate } = this.props;

    return (
      <div>
        <div className="generate-marks-button">
          {dateStringIsToday(marketDate) && allowGenerateMarks && (
            <button
              onClick={() =>
                this.generateMarks(CommodityType.AlbertaEnviromentals)
              }
              className="btn btn-sm btn-success float-sm-right"
            >
              {t("GenerateMarks")}
            </button>
          )}
        </div>
        <div className="duplicate-last-days-marks-button">
          {dateStringIsToday(marketDate) && allowCopyMarks && (
            <button
              onClick={() => this.generateMarks(CommodityType.None)}
              className="btn btn-sm btn-success float-sm-right"
            >
              {t("CopyPriorMarks")}
            </button>
          )}
        </div>
        <div className="marks-table">
          <Table
            className="table-marks"
            columns={columns}
            sortColumn={sortColumn}
            data={marks}
            valueProperty="qualityAttributeValueHash"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarksTable);
