import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import MarketData from "./marketData";
import Loading from "../../common/components/loading/loading";

const MarketDataAuthWrapper = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const userAccountId = initialized && keycloak.tokenParsed.OriginatingUserAccountID;
  const userOrganizations = initialized && keycloak.tokenParsed.group;
  const bypassSubscriptions = usePermitted(["operator", "administrator"]);

  return (
    <MarketData 
      userAccountId= {userAccountId}
      userOrganizations={ userOrganizations} 
      bypassSubscriptions ={bypassSubscriptions}
      marketsWithAttributes={props.marketsWithAttributes} 
      marketGroupMarkets={props.marketGroupMarkets}
      products={props.products}
      venues={props.venues}
      termSchedules={props.termSchedules}
      userAccountSubscriptionsByUserAccount={props.userAccountSubscriptionsByUserAccount}
    />
  );
};

export default MarketDataAuthWrapper;


