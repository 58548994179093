import React from 'react';
import { useTranslation} from 'react-i18next';

const Unauthorized = () => {
 const {t} = useTranslation();

 return (
   <div className="d-flex align-items-center justify-content-center">
     <h1>{t("Unauthorized")}</h1>
   </div>
 );
};

export default Unauthorized;