import React, { Component } from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { Tabs, Tab } from "react-bootstrap";
import {
  getQualityAttributeValue,
  addQualityAttributeValue,
  updateQualityAttributeValue,
} from "../../../common/services/products/qualityAttributeValueService";
import { getQualityAttributes } from "../../../common/services/products/qualityAttributeService";
import { getOrganizations } from "../../../common/services/auth/organizationService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import QualityAttributeValueHistory from "./qualityAttributeValueHistory";
import { withTranslation } from "react-i18next";

class QualityAttributeValue extends Form {
  state = {
    data: {
      qualityAttributeValueId: "",
      value: "",
      qualityAttributeId: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    qualityAttributes: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    qualityAttributeValueId: Joi.string(),
    value: Joi.string().required().label(this.props.t("QualityAttributeValue")),
    qualityAttributeId: Joi.string()
      .required()
      .label(this.props.t("QualityAttribute")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { qualityAttributeValueId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var qualityAttributeValue = {};

    if (!isNew) {
      qualityAttributeValue = await getQualityAttributeValue(
        qualityAttributeValueId
      );
      qualityAttributeValue.eventId = uuid();
    } //New User Add
    else {
      qualityAttributeValue = {
        qualityAttributeValueId: qualityAttributeValueId,
        value: "",
        qualityAttributeId: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({
      data: this.mapToViewModel(qualityAttributeValue),
      isLoading: false,
    });

    this.setState({ qualityAttributes: (await getQualityAttributes()).filter(x => x.active || x.qualityAttributeId === qualityAttributeValue.qualityAttributeId) });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(
        qualityAttributeValue.createdDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      updatedDate: new Date(
        qualityAttributeValue.updatedDate
      ).toLocaleDateString("en-US", dateFormatOptions),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(qualityAttributeValue) {
    return {
      qualityAttributeValueId: qualityAttributeValue.qualityAttributeValueId,
      value: qualityAttributeValue.value,
      qualityAttributeId: qualityAttributeValue.qualityAttributeId,
      active: qualityAttributeValue.active,
      eventId: qualityAttributeValue.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRevalue } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        const response = await updateQualityAttributeValue(this.state.data);
        toast.success(t("QualityAttributeValueUpdated"));
      } else {
        const response = await addQualityAttributeValue(this.state.data);
        this.setState({ isNew: false });
        onRevalue(
          this.state.data.qualityAttributeValueId,
          this.state.data.value
        );
        toast.success(t("QualityAttributeValueAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(this.state.data);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Value = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const {
      qualityAttributeValue,
      isLoading,
      isNew,
      createdDate,
      updatedDate,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, qualityAttributeValueId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("value", t("QualityAttributeValueName"))}
              {this.renderSelect(
                "qualityAttributeId",
                t("QualityAttribute"),
                this.state.qualityAttributes,
                "qualityAttributeId"
              )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <QualityAttributeValueHistory
                  qualityAttributeValueId={qualityAttributeValueId}
                />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["products"])(
  QualityAttributeValue
);
