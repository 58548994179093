import React, { Component } from "react";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import { getProductWithQualityAttributes } from "../../common/services/products/productService";
import { getVenue } from "../../common/services/markets/venueService";
import { getOrganizationsByUser } from "../../common/services/auth/userAccountOrganizationService";
import { getOrganizations } from "../../common/services/auth/organizationService";
import { getMarketGroupMarkets } from "../../common/services/markets/marketGroupMarketService";
import { getTradesByMarket } from "../../common/services/marketdata/tradeService";
import { getOrganizationSubscriptionsByMarket } from "../../common/services/marketdata/organizationSubscriptionService";
import { getUserAccountSubscriptionsByUserAccount } from "../../common/services/marketdata/userAccountSubscriptionService";
import TradesTable from "./tradesTable";
import TradeInput from "./tradeInput";
import { withTranslation } from "react-i18next";
import uuid from "react-uuid";
import Select from "../../common/components/form/select";
import Modal from "react-bootstrap/Modal";

class MarketTrades extends Component {
  state = {
    marketAttributes: [],
    organizations: [],
    trades: [],
    trade: null,
    organizationId: null,
    isLoading: true,
    tradeDate: null,
    termScheduleId: null,
    priceBasisId: null,
    currencyId: null,
    uomId: null,
    pricePrecision: 2,
    volumePrecision: 0,
    currencySymbol: "$",
    showModal: false, // Set the initial state to show the modal
    allowEdit: false, // Will use the props in conjunction with the trade context in the model/input to set this
  };

  async componentDidMount() {
    const { productId, market, allowEditOnBehalf, userAccountId } = this.props;
    const { marketId } = market;

    var date = new Date();
    const tradeDate = date.toLocaleDateString("fr-CA");

    const venue = await getVenue(market.venueId);
    const product = await getProductWithQualityAttributes(productId);

    //Build the organization select list.
    const myOrganizations = (
      await getOrganizationsByUser(userAccountId)
    ).filter((o) => o.active);
    const allOrganizations = allowEditOnBehalf
      ? (await getOrganizations())
          .filter((o) => o.active)
          .map(({ organizationId, name, active }) => ({
            organizationId,
            name,
            active,
          }))
      : [];

    const mySubscriptions = await getUserAccountSubscriptionsByUserAccount(
      userAccountId
    );
    
    const marketGroups = (await getMarketGroupMarkets()).filter(
      (m) => m.active
    );

    const subscribedItems = mySubscriptions.map((x) => x.subscribableId);

    const marketGroupMarkets = marketGroups.filter((m) =>
      subscribedItems.includes(m.marketGroupId)
    ).map((x) => x.marketId);

    const myMarketSubscriptions = mySubscriptions
      .filter((s) => (
        subscribedItems.includes(marketId)
        || marketGroupMarkets.includes(marketId)
      ))
      .map((x) => x.organizationId);

    const allSubscriptions = allowEditOnBehalf
      ? (await getOrganizationSubscriptionsByMarket(marketId)).map(
          (x) => x.organizationId
        )
      : [];

    //Build the full lost
    const organizationSuperset = [
      ...myOrganizations.filter(
        (o) =>
          myMarketSubscriptions.includes(o.organizationId) || allowEditOnBehalf
      ),
      ...allOrganizations.filter((o) =>
        allSubscriptions.includes(o.organizationId)
      ),
    ];

    //Remove any duplicates
    const organizations = _.uniqWith(organizationSuperset, _.isEqual);

    const timeZone =
      venue.timeZone +
      " (" +
      Intl.DateTimeFormat("us", {
        timeZoneName: "short",
        timeZone: venue.timeZone,
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName").value +
      ")";

    this.setState({
      organizations: organizations,
      product: product,
      //trades: trades,
      organizationId: organizations[0].organizationId,
      tradeDate: tradeDate,
      marketAttributes: market.marketAttributes,
      termScheduleId: market.termScheduleId,
      priceBasisId: market.marketAttributes[0].priceBasisId,
      currencyId: market.marketAttributes[0].currencyId,
      uomId: market.marketAttributes[0].uomId,
      pricePrecision: market.marketAttributes[0].pricePrecision ?? 2,
      volumePrecision: market.marketAttributes[0].volumePrecision ?? 0,
      currencySymbol: market.marketAttributes[0].currencySymbol ?? "$",
      timeZone: timeZone,
      isLoading: false,
    });

    this.state.organizationId &&
      this.props.market &&
      (await this.loadTrades(this.state.organizationId));
  }

  async loadTrades(organizationId) {
    const date = new Date();
    const lookbackWindow = 7; // trailing week
    const lookbackDate = new Date(date);
    lookbackDate.setDate(lookbackDate.getDate() - lookbackWindow); //Give 7 days grace on prior month trades
    var fromDate = new Date(
      lookbackDate.getFullYear(),
      lookbackDate.getMonth(),
      1
    ); //first of month of Lookback date

    const { market } = this.props;

    const trades = await getTradesByMarket(
      market.marketId,
      organizationId,
      fromDate.toLocaleDateString("fr-CA"),
      date.toLocaleDateString("fr-CA")
    );

    this.setState({ trades: trades });
  }

  handlemarketAttributeSelect = (marketAttribute) => {
    this.setState({
      priceBasisId: marketAttribute.priceBasisId,
      currencyId: marketAttribute.currencyId,
      uomId: marketAttribute.uomId,
    });
  };

  handleOrganizationChange = (organizationId) => {
    this.setState({ organizationId: organizationId });

    (async () => {
      const result = await this.loadTrades(organizationId);
    })();
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleUpdate = (trade) => {
    var trades = [...this.state.trades];
    const i = trades.findIndex((_trade) => _trade.tradeId === trade.tradeId);
    if (i > -1) trades[i] = trade;
    else trades.push(trade);

    this.setState({ showModal: false, trades: trades });
  };

  handleTradeSelected = (trade, isNew = false) => {
    const { allowEdit } = this.props;
    this.setState({
      trade: trade,
      isNew: isNew,
      showModal: true,
      allowEdit: allowEdit && trade.tradeState !== "Approved",
    });
    //this.setState({price: price, bid: bid, offer: offer, showModal: false})
  };

  render() {
    const {
      product,
      showModal,
      organizations,
      trades,
      organizationId,
      trade,
      marketAttributes,
      termScheduleId,
      isLoading,
      tradeDate,
      priceBasisId,
      currencyId,
      uomId,
      pricePrecision,
      volumePrecision,
      currencySymbol,
      isNew,
      timeZone,
      allowEdit,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, productId, market } = this.props;
    const { marketId } = market;

    var date = new Date();

    const endDate = date.toLocaleDateString("fr-CA");

    const newTrade = {
      tradeId: uuid(),
      tradeDate: date,
      marketId: marketId,
      organizationId: organizationId,
      priceBasisId: priceBasisId,
      currencyId: currencyId,
      uomId: uomId,
      active: true,
      eventId: uuid(),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 flex-column d-flex">
            <button
              onClick={() => this.handleTradeSelected(newTrade, true)}
              className="btn btn-sm btn-success float-sm-right"
              style={{ marginTop: "auto" }}
            >
              {t("AddTrade")}
            </button>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2 mt-auto">
            {t("TimeZone")}:<br />
            {timeZone}
          </div>
          <div className="col-md-2">
            <Select
              data-bs-theme="dark"
              idField="organizationId"
              name="name"
              disabled={Object.keys(organizations).length < 2}
              value={organizationId}
              label={t("Submitter")}
              options={organizations}
              onChange={(e) =>
                this.handleOrganizationChange(e.currentTarget.value)
              }
            />
          </div>
          <Modal
            show={showModal}
            onHide={this.handleClose}
            //centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header
              className="bg-dark text-light"
              data-bs-theme="dark"
              closeButton
            >
              <Modal.Title>
                {isNew
                  ? t("AddTrade")
                  : allowEdit
                  ? t("UpdateTrade")
                  : t("ViewTrade")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-dark text-light">
              <TradeInput
                organizationId={organizationId}
                marketId={marketId}
                product={product}
                termScheduleId={termScheduleId}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                tradeDate={tradeDate}
                trade={trade}
                //qualityAttributeValues={qualityAttributeValues}
                marketAttributes={marketAttributes}
                allowEdit={allowEdit}
                // onSave={this.handleClose}
                isNew={isNew} //Stub
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                submitCallbackFunction={this.handleUpdate}
              />
            </Modal.Body>
          </Modal>
          <div className="col">
            <TradesTable
              onRowClick={this.handleTradeSelected}
              organizationId={organizationId}
              trades={trades}
              product={product}
              marketId={marketId}
              termScheduleId={termScheduleId}
              tradeDate={tradeDate}
              priceBasisId={priceBasisId}
              currencyId={currencyId}
              uomId={uomId}
              pricePrecision={pricePrecision}
              volumePrecision={volumePrecision}
              currencySymbol={currencySymbol}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarketTrades);
