import React, { Component } from 'react';
import _ from 'lodash';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { withTranslation } from 'react-i18next';

class ReviewTradesToggle extends Component {

    render() { 
        const {t, tradeId, tradeState, handleApprove, handleReject} = this.props;

        return (
          <ButtonGroup style={{display: 'flex', alignItems:"center", justifyContent: 'center'}}  className="btn-group-toggle" data-toggle="buttons" aria-label="Basic example">
            <Button onClick={() => handleReject(tradeId)} disabled={tradeState==="Rejected"} variant={tradeState==="Rejected" ? "danger" : tradeState === "Submitted" ? "secondary": " dark"}>{tradeState==="Rejected" ? this.props.t("Rejected") : this.props.t("Reject")}</Button>
            <Button onClick={() => handleApprove(tradeId)} disabled={tradeState==="Approved"} variant={tradeState==="Approved" ? "success" :  tradeState === "Submitted" ? "secondary": " dark"}>{tradeState==="Approved" ? this.props.t("Approved") : this.props.t("Approve")}</Button>
          </ButtonGroup>
        );
    }
}

export default withTranslation(["marketdata"])(ReviewTradesToggle);