import React, { Component } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getUoms } from "../../../common/services/markets/uomService";
import SearchBox from "../../../common/components/search/searchBox";
import UomsTable from "./uomsTable";
import { withTranslation } from "react-i18next";

class Uoms extends Component {
  constructor(props) {
    super(props);

    this.handleUomUpdated = this.handleUomUpdated.bind(this);
  }

  state = {
    uoms: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {
    this.setState({ uoms: await getUoms(), isLoading: false });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleUomSelected = (uom, isNew = false) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(uom, this.handleUomUpdated, isNew);
  };

  handleUomUpdated = (uom) => {
    const uoms = [...this.state.uoms];
    const index = uoms.findIndex((u) => u.uomId === uom.uomId);
    if (index > -1) {
      uoms.splice(index, 1);
    }
    uoms.push(uom);
    this.setState({ uoms: uoms });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      uoms: allUoms,
      activeOnly,
    } = this.state;

    let filtered = allUoms;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.name != null &&
            u.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (u.code != null &&
            u.code.toLowerCase().includes(searchQuery.toLowerCase()))
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const uoms = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: uoms };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, user, onClick, allowEdit } = this.props;

    const { totalCount, data: uoms } = this.getPagedData();

    const newUom = {
      uomId: uuid(),
      name: t("NewUom"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() => this.handleUomSelected(newUom, true)}
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddUom")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedUom", { count: totalCount })}</p>
        <UomsTable
          uoms={uoms}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleUomSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(Uoms);
