import React, { Component } from "react";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { getMarkets } from "../../../common/services/markets/marketService";
import {
  getMarketsByMarketGroup,
  assignMarket,
  revokeMarket,
} from "../../../common/services/markets/marketGroupMarketService";
import Loading from "../../../common/components/loading/loading";
import DualListBox from "../../../common/components/form/dualListBox";
import { withTranslation } from "react-i18next";

class MarketGroupMarkets extends Component {
  state = {
    options: [],
    selected: [],
    isLoading: true,
  };

  async componentDidMount() {
    const { marketGroupId } = this.props;
    const allMarkets = await getMarkets();
    const markets = await getMarketsByMarketGroup(marketGroupId);

    this.setState({
      options: this.mapToOptionsViewModel(allMarkets),
      selected: this.mapToSelectedViewModel(markets),
      isLoading: false,
    });
  }

  mapToOptionsViewModel(markets) {
    return markets.map((market) => ({
      value: market.marketId,
      label: market.name,
    }));
  }

  mapToSelectedViewModel(markets) {
    return markets.map((market) => market.marketId);
  }

  async handleChange(selected, selection, controlKey) {
    const previousSelected = { ...this.state.selected };
    const { marketGroupId } = this.props;
    const eventId = uuid();

    if (controlKey !== "available" && controlKey !== "selected") {
      toast.warning(this.props.t("UnexpectedError"));
      return null;
    }

    // set the initial state,will clean up at the end if there are failures.
    this.setState({ selected: selected });

    for (const marketId of selection) {
      if (controlKey === "available") {
        try {
          await assignMarket({
            marketGroupId: marketGroupId,
            marketId: marketId,
            eventId: eventId,
          });
          toast.success(this.props.t("MarketAdded"));
        } catch (ex) {
          console.log(ex);
          var index = selected.indexOf(marketId);
          delete selected[index];
          toast.warning(this.props.t("MarketAdditionFailed"));
        }
      } else if (controlKey === "selected") {
        try {
          await revokeMarket(marketGroupId, marketId);
          toast.success(this.props.t("MarketRemoved"));
        } catch (ex) {
          console.log(ex);
          selected.push(marketId);
          toast.warning(this.props.t("MarketRemovalFailed"));
        }
      }
    }

    //Clean up with the actual outcome state
    this.setState({ selected: selected });
  }

  render() {
    const { options, selected, isLoading } = this.state;
    const { allowEdit } = this.props;
    if (isLoading) return <Loading />;

    return (
      <div>
        <DualListBox
          canFilter
          className="rdl-hide-move-all"
          disabled={!allowEdit}
          options={options}
          selected={selected}
          onChange={(selected, selection, controlKey) =>
            this.handleChange(selected, selection, controlKey)
          }
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(MarketGroupMarkets);
