import React , { useEffect, useState } from "react";

const DashboardContainer = ({ fileName }) => {

  const iframeSrc = '/'+fileName;

  return (      
      <iframe className="iframe" src={iframeSrc} height="100%" width="100%" style={{verticalAlign:"bottom"}}/>         
  );
};

export default DashboardContainer;
