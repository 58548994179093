import { useKeycloak } from "@react-keycloak/web";
import {usePermitted} from "../../components/permissions/permissions";
import UnauthorizedPage from "../../../App/pages/Unauthorized";
import Loading from './../loading/loading';
import React from 'react';

const ProtectedRoute = ({ children, permittedRoles }) => {
  const { keycloak, initialized } = useKeycloak();
  const permitted = usePermitted(permittedRoles);
  //const isLoggedIn = keycloak.authenticated;
  
  if (!initialized) {
    return <Loading />;
  }

  if (permitted)
  {
    return children
  }
  else if (!permitted && permitted!==false)
  {
    return <Loading />;
  }  
  else if (permitted===false)
  {
    return <UnauthorizedPage/>
  }
  else
  {
    //Should never land here, but just in case.
    return <Loading />;
  } 
};

export default ProtectedRoute;