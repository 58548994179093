import React, { Component } from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import _ from "lodash";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import {
  addMark,
  updateMark,
  deleteMark,
} from "../../common/services/marketdata/markService";
import Form from "../../common/components/form/form";
import Loading from "../../common/components/loading/loading";
import { withTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import Modal from "react-bootstrap/Modal";

class MarkInput extends Form {
  state = {
    data: {
      markId: null,
      organizationId: null,
      marketId: null,
      termId: null,
      priceBasisId: null,
      currencyId: null,
      uomId: null,
      marketDate: null,
      qualityAttributeValues: [],
      qualityAttributeValueHash: null,
      active: null,
      price: null,
      bid: null,
      offer: null,
      open: null,
      high: null,
      low: null,
      close: null,
      override: false,
      eventId: null,
      marketState: null,
    },
    createdDate: null,
    updatedDate: null,
    errors: {},
    isLoading: true,
    collapseOpen: false,
    showModal: false,
  };

  schema = {
    markId: Joi.string().required(),
    marketId: Joi.string().required(),
    organizationId: Joi.string().required(),
    termId: Joi.string().required(),
    priceBasisId: Joi.string().required(),
    currencyId: Joi.string().required(),
    uomId: Joi.string().required(),
    marketDate: Joi.date().required(),
    qualityAttributeValues: Joi.array().items(),
    qualityAttributeValueHash: Joi.string().required(),
    //Precision is not working, need to come back to this.
    price: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Price")),
    bid: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Bid")),
    offer: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Offer")),
    open: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Open")),
    high: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("High")),
    low: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Low")),
    close: Joi.number()
      .precision(this.props.pricePrecision ?? 2)
      .allow("")
      .label(this.props.t("Close")),
    override: Joi.boolean().label(this.props.t("Override")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string().required(),
  };

  async componentDidMount() {
    //try{
    const {
      organizationId,
      marketId,
      termId,
      priceBasisId,
      currencyId,
      uomId,
      allowEdit,
      allowOVerrideMarks,
      pricePrecision,
      marketDate,
      qualityAttributeValues,
      isNew,
      t,
    } = this.props;

    const {
      markId,
      active,
      price,
      bid,
      offer,
      open,
      high,
      low,
      close,
      override,
      qualityAttributeValueHash,
      eventId,
    } = this.props.mark;

    this.setState({ isNew: isNew, collapseOpen: !allowEdit });

    var mark = {
      marketId: marketId,
      organizationId: organizationId,
      termId: termId,
      priceBasisId: priceBasisId,
      currencyId: currencyId,
      uomId: uomId,
      marketDate: marketDate,
      qualityAttributeValues: qualityAttributeValues,
      qualityAttributeValueHash: qualityAttributeValueHash,
      markId: markId,
      active: active,
      price: price ?? "",
      bid: bid ?? "",
      offer: offer ?? "",
      open: open ?? "",
      high: high ?? "",
      low: low ?? "",
      close: close ?? "",
      override: override ?? false,
      eventId: eventId,
    };

    this.setState({ data: mark, isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(mark.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(mark.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;
    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        const response = await updateMark(
          _.mapValues(data, (v) => (v === "" ? null : v))
        ); //Address optional fields by flipping them back to null so C# can properly parse them
        // //Call back to the cell list to allow it to close the modal and update it's display value.
        this.props.submitCallbackFunction(response);
        toast.success(t("MarkUpdated"));
      } else {
        const response = await addMark(
          _.mapValues(data, (v) => (v === "" ? null : v))
        ); //Address optional fields by flipping them back to null so C# can properly parse them
        this.setState({ isNew: false });
        // //Call back to the cell list to allow it to close the modal and update it's display value.
        this.props.submitCallbackFunction(response);
        toast.success(t("MarkAdded"));
      }
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Price = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  deleteMark = async () => {
    const { t } = this.props;
    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      data.active = false;
      data.markState = "";
      this.setState({ data: data });

      const response = await deleteMark(
        _.mapValues(data, (v, key) => {
          if (key === "markState") {
            return v;
          }
          return v === "" ? null : v;
        })
      );
      this.props.submitCallbackFunction(response);
      this.handleModalClose();
      toast.success(t("MarkDeleted"));
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Price = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      isLoading,
      isNew,
      createdDate,
      updatedDate,
      collapseOpen,
      showModal,
    } = this.state;

    if (isLoading) return <Loading />;

    const {
      t,
      termName,
      shortName,
      qualityAttributeValues,
      allowEdit,
      allowOverrideMarks,
    } = this.props;

    return (
      <div>
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              <div className="row">
                <div className="col">
                  {shortName}
                  {qualityAttributeValues.map((x) => " - " + x.value)}
                </div>
                <div className="col">
                  {t("Term") + ": "}
                  {termName}
                </div>
              </div>
              {allowOverrideMarks && (
                <div className="row">
                  <div className="col">
                    {this.renderSwitch("override", t("Override"))}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  {this.renderInput("price", t("Price"))}
                </div>
              </div>
              <div className="row">
                <div className="col">{this.renderInput("bid", t("Bid"))}</div>
                <div className="col">
                  {this.renderInput("offer", t("Offer"))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-secondary float-sm-right"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ collapseOpen: !collapseOpen });
                  }}
                >
                  {collapseOpen ? "-" : "+"}
                </button>
              </div>
              <Collapse in={collapseOpen}>
                <div>
                  <div className="row">
                    <div className="col">
                      {this.renderInput("high", t("High"))}
                    </div>
                    <div className="col">
                      {this.renderInput("low", t("Low"))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {this.renderInput("open", t("Open"))}
                    </div>
                    <div className="col">
                      {this.renderInput("close", t("Close"))}
                    </div>
                  </div>
                </div>
              </Collapse>
              {allowEdit && this.renderButton(t("Save"), "submit")}
              {allowEdit && !isNew && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ showModal: true });
                  }}
                  className="btn btn-sm btn-delete float-sm-right"
                  style={{ marginLeft: "15px" }}
                >
                  {t("Delete")}
                </button>
              )}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        <div className={`dark-overlay ${showModal ? "active" : ""}`}></div>
        <Modal
          show={showModal}
          size="sm"
          onHide={this.handleModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="bg-dark text-light">
            <div className="row" style={{ textAlign: "center" }}>
              <p>{t("ConfirmDeleteMark")}</p>
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col" style={{ textAlign: "center" }}>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleModalClose}
                >
                  {t("Cancel")}
                </button>
              </div>
              <div className="col" style={{ textAlign: "center" }}>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={this.deleteMark}
                >
                  {t("Delete")}
                </button>
              </div>
              <div className="col"></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarkInput);
