import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import UserAccountAdmin from "./userAccounts/userAccountAdmin";
import OrganizationAdmin from "./organizations/organizationAdmin";
import RoleAdmin from "./roles/roleAdmin";
import SubscriptionAdmin from "./subscriptions/subscriptionAdmin";
import { useTranslation } from "react-i18next";

const AuthAdmin = () => {
  const queryUserAccounts = usePermitted(["api-query-user-accounts"]);
  const manageUserAccounts = usePermitted(["api-manage-user-accounts"]);
  const queryOrganizations = usePermitted(["api-query-organizations"]);
  const manageOrganizations = usePermitted(["api-manage-organizations"]);
  const queryRoles = usePermitted(["api-query-roles"]);
  const manageRoles = usePermitted(["api-manage-roles"]);
  const queryOrganizationSubscriptions = usePermitted([
    "api-query-organization-market-subscriptions",
  ]);
  const manageOrganizationSubscriptions = usePermitted([
    "api-manage-organization-market-subscriptions",
  ]);
  const { t } = useTranslation(["auth"]);

  return (
    <Tabs
      className="nav-dark"
      defaultActiveKey={1}
      id="admin-tabs"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {queryUserAccounts && (
        <Tab eventKey={1} title={t("Users")}>
          <UserAccountAdmin allowEdit={manageUserAccounts} />
        </Tab>
      )}
      {queryOrganizations && (
        <Tab eventKey={2} title={t("Organizations")}>
          <OrganizationAdmin allowEdit={manageOrganizations} />
        </Tab>
      )}
      {queryRoles && (
        <Tab eventKey={3} title={t("Roles")}>
          <RoleAdmin allowEdit={manageRoles} />
        </Tab>
      )}
      {queryOrganizationSubscriptions && (
        <Tab eventKey={4} title={t("Subscriptions")}>
          <SubscriptionAdmin allowEdit={manageOrganizationSubscriptions} />
        </Tab>
      )}
    </Tabs>
  );
};

export default AuthAdmin;

// class AuthAdmin extends Component {

//     render() {
//         return (

//         );
//     }
// }

// export default AuthAdmin;
