import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
    return (  
        <div className="loading-container" >
            <Spinner animation="border" variant="secondary" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}
 
export default Loading;

