import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../common/components/table/table';
import Loading from '../../common/components/loading/loading';
import { getProductWithQualityAttributes} from "../../common/services/products/productService";
import { getTermsByDate} from "../../common/services/markets/termService";
//import TradeCell from "./tradeCell";
import { SHA256 } from 'crypto-js';
import {usePermitted} from "../../common/components/permissions/permissions";
import { withTranslation } from 'react-i18next';
import Form from "react-bootstrap/Form";
import ListGroup from 'react-bootstrap/ListGroup';
import uuid from 'react-uuid';

class TradesTable extends Component {
    state = {
        trades: [],
        columns: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'tradeDate', order: 'desc'},
        isLoading: true,
   };

    async componentDidMount() {
        await this.initializeTable(this.props);
    };

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.marketDate !== this.props.marketDate ||
            previousProps.priceBasisId !== this.props.priceBasisId  ||
            previousProps.currencyId !== this.props.currencyId  ||
            previousProps.uomId !== this.props.uomId ||
            previousProps.organizaitonId !== this.props.organizaitonId ||
            !_.isEqual(previousProps.trades, this.props.trades)
        )
        {
            await this.initializeTable(this.props);
        }
    };

    async initializeTable(passedProps)
    {
        const {organizationId, product, marketId, trades, termScheduleId} = passedProps;

        const columns = this.mapToColumns(product)                
        
        this.setState({trades: this.mapToViewModel(trades), columns: columns, isLoading:false});
    };

    mapToColumns(products)
    {
        const
        {
            organizationId,
            marketId,            
            marketDate,
            priceBasisId,
            currencyId,
            uomId,
            pricePrecision,
            volumePrecision,
            currencySymbol  
        } = this.props;

        var columns = [
            {path: 'shortName', label: this.props.t('MarketDataProduct')}, //Set the product name as the first column
            ...products.qualityAttributes
            .filter(qualityAttribute => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
            .map(qualityAttribute => ( //Add one new column for each qualityAttribute
                {
                    path: qualityAttribute.qualityAttributeId,
                    label: qualityAttribute.qualityAttributeName,
                }
            )),
            {path: 'termName', label: this.props.t('Term')},
            {path: 'priceFormatted', label: this.props.t('Price')},
            {path: 'volumeFormatted', label: this.props.t('Volume')},
            {path: 'tradeDateFormatted', label: this.props.t('TradeDate')},
        ]

        return columns;
    }

    mapToViewModel(trades)
    {
        const dateFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            // hour: 'numeric',
            // hour12: false,
            // minute: 'numeric',
            // second: 'numeric'
        };

        const mappedTrades = 
        trades && trades.map(trade => (
        { ...{shortName: this.props.product.shortName},
         ...trade,
         ...{
            tradeDateFormatted: new Date(trade.tradeDate).toLocaleDateString('en-US', dateFormatOptions),
            priceFormatted: this.props.currencySymbol + Number(trade.price).toFixed(this.props.pricePrecision),
            volumeFormatted: Number(trade.volume).toFixed(this.props.volumePrecision)
         }, //Override with formatting.
         ...trade.qualityAttributeValues
         .reduce((acc, attribute) => {
             acc[attribute.qualityAttributeId] = attribute.value;
             return acc;
         }, {})   
        }
        ));

        return mappedTrades;
    }

    render() { 
        const {sortColumn, isLoading, columns, trades} = this.state;

        if (isLoading) return (<Loading/>);

        const {t, onRowClick, productId} = this.props;

        return (
            <Table className="table-submit-trades" columns={columns} sortColumn = {sortColumn} onRowClick={onRowClick} data={trades} valueProperty="tradeId"/>
        );
    }
}

export default withTranslation(["marketdata"])(TradesTable)