import { getAggregateMarksByMarket } from "./markService";
import { getSpotByTermSchedule } from "../markets/termService"
import { getMarket } from "../markets/marketService"
import { formatDate } from "../utilities";
import { aggregateMarks, aggregateMarksWithoutAttributes } from "./marketDataUtilities";
import { getSubscribedMarkets } from "./subscriptionUtilities";
import _ from 'lodash';

export async function getSubscribedMarketPrices(userAccountId, marketDate) {

  const subscribedMarkets = await getSubscribedMarkets(userAccountId);

  var marketPrices = []
  for (const market of subscribedMarkets)
  {
    const { marketId } = market;
    const marketIdPrices = await getMarketPricesByMarket(marketId, marketDate);

    marketPrices = marketPrices.concat(marketIdPrices);
  }

  return marketPrices;
}

export async function getMarketPricesByMarket(marketId, marketDate) {

  // const {data: marketPrices_old} = await http.get(apiEndpoint+"byMarket/"+marketId+"/"+marketDate);
  // console.log(marketPrices_old)

  const date = new Date(marketDate);

  const fromDate = formatDate(new Date(date.getFullYear(), date.getMonth() -1, 1));

  const market = await getMarket(marketId);
  const spotTerms = await getSpotByTermSchedule(market.termScheduleId, fromDate, marketDate);
  const spotTermFilter = spotTerms.map(x => x.termId+x.marketDate);

  const marks = (await getAggregateMarksByMarket(marketId, fromDate, marketDate)).filter(x => x.markState = "Approved");

  const spotMarks = marks.filter(x=> spotTermFilter.includes(x.termId+x.marketDate));
  const nonSpotMarks = marks.filter(x=> !spotTermFilter.includes(x.termId+x.marketDate));

  let marketPrices = [];  

  //Get spot market prices rolled up agross Attributes
  const spotMarksWithoutAttributes = (await aggregateMarksWithoutAttributes(spotMarks)).filter(x => x.price!== undefined && x.price !== null && !isNaN(x.price));

  spotMarksWithoutAttributes.sort(function (a, b) { return new Date(b.marketDate) - new Date(a.marketDate)  });

  const latestMarketPriceWithoutAttributes = {...spotMarksWithoutAttributes[0], termName: "Latest", qualityAttributeValues: null, previousPrice: spotMarksWithoutAttributes.length > 1 ? spotMarksWithoutAttributes[1].price : undefined};

  marketPrices.push(latestMarketPriceWithoutAttributes);

  //Get non-spot market prices rolled up agross Attributes
  const nonSpotMarksWithoutAttributes = (await aggregateMarksWithoutAttributes(nonSpotMarks)).filter(x => x.price!== undefined && x.price !== null && !isNaN(x.price));

  const nonSpotMarksWithoutAttributesGroupedArray = _.groupBy(nonSpotMarksWithoutAttributes, obj => obj.termId);

  const nonSpotMarketPriceWithoutAttributes = _.map(nonSpotMarksWithoutAttributesGroupedArray, group => {
    group.sort(function (a, b) { return new Date(b.marketDate) - new Date(a.marketDate)  });
    return {...group[0], previousPrice: group.length > 1 ? group[1].price : undefined};
  });

  marketPrices = marketPrices.concat(nonSpotMarketPriceWithoutAttributes);

  //Get spot market prices rolled up MTD
  const spotMarksAggregated = await aggregateMarks(spotMarks);

  const mtdGroupedArray = _.groupBy(spotMarksAggregated, obj =>
    _.join([new Date((new Date(obj.marketDate)).getFullYear(), (new Date(obj.marketDate)).getMonth() -1, 1)], '|')
  );

  const mtdSpotMarks = _.map(mtdGroupedArray, group => {
      const {
      instrumentId,
      termId,
      qualityAttributeValueHash,
      marketDate,
      currencyId,
      marketId,
      marketName,
      priceBasisId,
      uomId,
      } = group[0];
      
      const filteredPrice = group.map(item => item.price).filter(value => value !== undefined && value !== null && !isNaN(value));
      const price = _.mean(filteredPrice);

      const high = _.maxBy(group, record => (record.high !== null ? record.high : -Infinity)).high;
      const low = _.minBy(group, record => (record.low !== null ? record.low : Infinity)).low;
      
      const filteredOpen = group.map(item => item.open).filter(value => value !== undefined && value !== null);
      const open = _.mean(filteredOpen);
      const filteredClose = group.map(item => item.close).filter(value => value !== undefined && value !== null);
      const close = _.mean(filteredClose);

      return {
      instrumentId,
      termId,
      qualityAttributeValueHash,
      marketDate: new Date((new Date(marketDate)).getFullYear(), (new Date(marketDate)).getMonth() -1, 1),
      currencyId,
      marketId,
      marketName,            
      priceBasisId,
      uomId,            
      price,
      high,
      low,
      open,
      close,
      };
  });

  mtdSpotMarks.sort(function (a, b) { return new Date(b.marketDate) - new Date(a.marketDate)  });

  const mtdSpotPrice = {...mtdSpotMarks[0], termName: "MTD", qualityAttributeValues: null, previousPrice: mtdSpotMarks.length > 1 ? mtdSpotMarks[1].price : undefined};

  marketPrices.push(mtdSpotPrice);

  //Get spot market prices with full detail
  //Holding this back because a) too much data, b) difficult to isolate, and to display attributes.
  // const groupedArray = _.groupBy(spotMarks, obj =>
  //   _.join([obj.qualityAttributeValueHash], '|')
  // );
  //
  // const spotPrices = _.map(groupedArray, group => {
  //   group.sort(function (a, b) { return a.marketDate - a.marketDate  });
  //   console.log(group[0].qualityAttributeValueHash, market)
  //   return {...group[0], previousPrice: group.length > 1 ? group[1].price : undefined};
  // });
  //
  // marketPrices = marketPrices.concat(spotPrices);

  return marketPrices;
}