import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getMarketAttributes } from "../../../common/services/markets/marketAttributeService";
//import MarketAttributeCell from "./marketAttributeCell";
import { withTranslation } from "react-i18next";

class MarketAttributesTable extends Component {
  state = {
    marketAttributes: [],
    columns: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "marketAttributeDate", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    await this.setState({
      marketAttributes: await getMarketAttributes(this.props.marketId),
    });
    await this.initializeTable(this.props);
  }

  async componentDidUpdate(previousProps) {
    if (
      !_.isEqual(previousProps.marketAttributes, this.props.marketAttributes)
    ) {
      await this.initializeTable(this.props);
    }
  }

  async initializeTable(passedProps) {
    const { marketAttributes } = passedProps;

    const columns = this.mapToColumns();
    this.setState({
      marketAttributes: this.mapToViewModel(marketAttributes),
      columns: columns,
      isLoading: false,
    });
  }

  mapToColumns() {
    var columns = [
      { path: "priceBasisName", label: this.props.t("PriceBasis") },
      { path: "currencyName", label: this.props.t("Currency") },
      { path: "uomName", label: this.props.t("Uom") },
      { path: "pricePrecision", label: this.props.t("PricePrecision") },
      { path: "volumePrecision", label: this.props.t("VolumePrecision") },
    ];

    return columns;
  }

  mapToViewModel(marketAttributes) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      // hour: 'numeric',
      // hour12: false,
      // minute: 'numeric',
      // second: 'numeric'
    };

    const mappedMarketAttributes = marketAttributes.map((marketAttribute) => ({
      ...marketAttribute,
    }));

    return mappedMarketAttributes;
  }

  render() {
    const { sortColumn, isLoading, columns, marketAttributes } = this.state;

    if (isLoading) return <Loading />;

    const { t, onRowClick } = this.props;

    return (
      <div className="marketAttributes-table">
        <Table
          className="table table-dark table-sm"
          columns={columns}
          sortColumn={sortColumn}
          onRowClick={onRowClick}
          data={marketAttributes}
          valueProperty="marketId"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(MarketAttributesTable);
