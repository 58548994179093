import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FontAwesome from "react-fontawesome";
import FontAwesomeIcon from "react-fontawesome";

class MarketPriceTile extends Component {

    render() { 

        const {marketPrice, pricePrecision, currencySymbol, t} = this.props;
        const price = currencySymbol+marketPrice.price.toFixed(pricePrecision);
        const change = marketPrice.price - marketPrice.previousPrice;
        const changeString = marketPrice.previousPrice ? (currencySymbol+change.toFixed(pricePrecision)) : "-";
        const changePct = (marketPrice.previousPrice ?  ((100*((marketPrice.price - marketPrice.previousPrice)/marketPrice.price)).toFixed(2)) : "- ") +"%"
        const color = change < 0 ? "DarkRed" : "Green";
        const caretIcon = change < 0 ? "caret-down" : "caret-up";

        return (
            <div className="container-fluid" >
                <div className="row" style={{color:color, textAlign: "center"}}>
                <div className="col-md-3" style={{color:"white", textAlign: "left"}}>
                        <h2>{marketPrice.termName}:</h2>
                    </div>                        
                    <div className="col-md-3" style={{color:"grey"}}>
                        <h2>{price}</h2>
                    </div>    
                    <div className="col-md-3">
                        <h2><FontAwesome name={caretIcon}/>{" "}{changeString}</h2>
                    </div>    
                    <div className="col-md-3">
                        <h2><FontAwesome name={caretIcon}/>{" "}{changePct}</h2>
                    </div>    
            
                </div>                
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(MarketPriceTile)