import { temperatureLow } from "fontawesome";

export function joinArrays(array1, array2, commonField) 
{
    return array1.map(item1 => {
        const matchingItem = array2.find(item2 => item2[commonField] === item1[commonField]);
        
        // Merge the objects from both arrays based on the common field
        return { ...item1, ...matchingItem };
    });
}

export function formatDate(date)
{
    const formattedDate = new Date(Date.parse(date)).toLocaleDateString("sv-SE", {
        timeZone: 'UTC',      
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });

    return formattedDate === "Invalid Date" ? "" : formattedDate;
}

export function formatDateTime(dateTime)
{
    const formattedDateTime = new Date(Date.parse(dateTime)).toLocaleString("sv-SE", {
        timeZone: 'UTC',
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }).replace(/\s+/g, 'T');

    return formattedDateTime === "Invalid Date" ? "" : formattedDateTime;
}

export function dateStringIsToday(dateString) {
    // Parse the input date string and create a UTC date
    const inputDate = new Date(
      Date.UTC(
        parseInt(dateString.substring(0, 4)),
        parseInt(dateString.substring(5, 7)) - 1,
        parseInt(dateString.substring(8, 10))
      )
    );

    // Get today's date and create a UTC date
    const today = new Date();
    const todayUTC = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    );

    // Extract year, month, and day from both dates
    const inputYear = inputDate.getUTCFullYear();
    const inputMonth = inputDate.getUTCMonth();
    const inputDay = inputDate.getUTCDate();

    const todayYear = todayUTC.getUTCFullYear();
    const todayMonth = todayUTC.getUTCMonth();
    const todayDay = todayUTC.getUTCDate();

    // Compare year, month, and day
    return (
      inputYear === todayYear &&
      inputMonth === todayMonth &&
      inputDay === todayDay
    );
  }
  
  export function isBusinessDay(date){
    var day = new Date(date).getDay();
    if(day == 0 || day == 6  ){
        return false;
    }
    return true;
   }

   export function priorBusinessDay(date){
    var day = new Date(date);
    while (!isBusinessDay(day)) { day.setDate(day.getDate() - 1) }
    return day;
   }