import React from 'react';
import { useTranslation} from 'react-i18next';

const SearchBox = ({value, onChange}) => {
    const {t} = useTranslation();

    return (  
        <input
        type="text"
        name="query"
        className="form-control form-control-sm"
        data-bs-theme="dark"
        placeholder={t("SearchPrompt")}
        value={value}
        onChange={ e => onChange(e.currentTarget.value)}
        />
    );
};
 
export default SearchBox;