import { useKeycloak } from "@react-keycloak/web";
import _ from 'lodash';

export function usePermitted (permittedRoles) {
    const { keycloak, initialized } = useKeycloak();
    try{
        return permittedRoles? _.intersection(permittedRoles, keycloak.tokenParsed.role).length > 0: true;
    }
    catch{
        return false;
    }
}
