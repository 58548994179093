import React, { Component } from "react";
import Joi from "joi-browser";
import Switch from "./switch";
import Input from "./input";
import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const allowUnknown = this.state.joiAllowUnknown ?? false;
    const options = { abortEarly: false, allowUnknown: allowUnknown };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) error[item.path[0]] = item.message;

    return error;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);

    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    if (input.type === "checkbox") {
      data[input.name] = !data[input.name];
    } else {
      data[input.name] = input.value;
    }
    this.setState({ data, errors });

    //console.log(this.validate(), data, this.state.data);
  };

  renderButton(label, type="") {
    return (
      <button type={type} disabled={this.validate()} className="btn btn-primary btn-sm">
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        className="form-control form-control-sm"
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderDateInput(name, label, min = null, max = null, type = "date") {
    const { data, errors } = this.state;
    return (
      <Input
        className="form-control form-control-sm"
        min={min}
        max={max}
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderDateTimeInput(name, label, min = null, max = null, type = "datetime-local") {
    const { data, errors } = this.state;
    return (
      <Input
        className="form-control form-control-sm"
        min={min}
        max={max}
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
      />
    );
  }


  renderDisabledInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        className="form-control form-control-sm"
        disabled="disabled"
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSwitch(name, label) {
    const { data, errors } = this.state;
    //return (<Switch className="switch" type="switch" name={name} label={label} value={data[name]} onChange={this.handleChange} error={errors[name]}/>);
    return (
      <Switch
        type="switch"
        name={name}
        label={label}
        value={data[name]}
        checked={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, options, idField = "_id", optionField = "name") {
    const { data, errors } = this.state;

    return (
      <Select
        className="form-control form-control-sm"
        idField={idField}
        optionField={optionField}
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderDisabledSelect(
    name,
    label,
    options,
    idField = "_id",
    optionField = "name"
  ) {
    const { data, errors } = this.state;
    return (
      <Select
        className="form-control form-control-sm"
        disabled="disabled"
        idField={idField}
        optionField={optionField}
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
}

export default Form;
