import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../common/components/table/table';
import Loading from '../../common/components/loading/loading';
import { getProductWithQualityAttributes} from "../../common/services/products/productService";
import { pivotQualityAttributes} from "../../common/services/products/productUtilities";
import { getSpotByTermSchedule} from "../../common/services/markets/termService";
import { getAggregateMarksByMarket} from "../../common/services/marketdata/markService";
import { joinArrays } from "../../common/services/utilities";
import MarkCell from "./markCell";
import CandlestickChart from '../../common/components/d3/candlestickChart';
import { SHA256 } from 'crypto-js';
import {usePermitted} from "../../common/components/permissions/permissions";
import { withTranslation } from 'react-i18next';
import Form from "react-bootstrap/Form";
import ListGroup from 'react-bootstrap/ListGroup';
import uuid from 'react-uuid';
import {aggregateMarks} from "../../common/services/marketdata/marketDataUtilities";
import {formatDate} from "../../common/services/utilities";

class HistoricalMarketDataTable extends Component {

    state = {
        marks: [],
        qualityAttributeValueHash: null,
        candlestickMarksRaw: [],
        startDate: null,
        endDate: null,
        yMax: null,
        yMin: null,
        columns: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true,
   };

    async componentDidMount() {
        await this.initializeTable(this.props);
    };

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.fromDate !== this.props.fromDate ||
            previousProps.toDate !== this.props.toDate ||
            !_.isEqual(previousProps.marks, this.props.marks)            
        )
        {
            await this.initializeTable(this.props);
        }
    };

    async initializeTable(passedProps)
    {
        const {marks, organizationId, product, marketId, fromDate, toDate, marketDate, termScheduleId, priceBasisId, currencyId, uomId} = passedProps;

        const terms = await getSpotByTermSchedule(termScheduleId, fromDate.toLocaleDateString('fr-CA'), toDate.toLocaleDateString('fr-CA'));

        const aggregatedMarks= aggregateMarks(marks);

        const pivotedData = pivotQualityAttributes(product);

        const columns = this.mapToColumns(pivotedData);
     
        const joinedData = this.joinMarks(aggregatedMarks, terms)

        const candlestickMarksRaw = marks.filter(m => columns.map(x =>  x.path).includes(m.qualityAttributeValueHash) && terms.map(t => t.marketDate+t.termId).includes(m.marketDate+m.termId));

        this.setState({marks: joinedData, candlestickMarksRaw: candlestickMarksRaw, startDate: new Date(fromDate), endDate: new Date(toDate), columns: columns, isLoading:false});

    };

    mapToColumns(pivotedData)
    {
        const
        {
            organizationId,
            marketId,            
            marketDate,
            historicalReportingPeriodicity,
            priceBasisId,
            currencyId,
            uomId,
            pricePrecision,
            volumePrecision,
            currencySymbol  
        } = this.props;

        const dataWithHashes = pivotedData.map(obj =>
            {
                // Extract and sort the values of the QualityAttributeValueId field
                const sortedQualityAttributeValues = obj.qualityAttributeValues
                    .map(qualityAttributeValue => qualityAttributeValue.qualityAttributeValueId)  // Extract the values
                    .filter(value => value !== undefined)  // Filter out undefined values
                    .sort();  // Sort the values
    
                    // Concatenate the sorted values
                    const concatenatedValues = sortedQualityAttributeValues.join('');
                    const qualityAttributeValueHash = SHA256(concatenatedValues).toString().toUpperCase();
    
                    return {...obj, qualityAttributeValueHash}
            });

         var columns = [
         {path: 'marketDate', label: this.props.t('MarketDate'), content: data => <span>{data.marketDate.slice(0,10)+ (historicalReportingPeriodicity === "Hour" ? "H"+data.marketDate.slice(11,13): "")}</span>}, //Set the product name as the first column
         ...dataWithHashes
            .map(x => (
                    {
                        path: x.qualityAttributeValueHash,
                        label: Object.keys(x.qualityAttributeValues).map(function(k){return x.qualityAttributeValues[k].value}).join("|"),
                        content: data =>
                        <MarkCell                         
                            termId={data.termId} 
                            organizationId={organizationId}
                            marketId={marketId}
                            priceBasisId={priceBasisId}
                            currencyId={currencyId}
                            uomId={uomId}
                            pricePrecision={pricePrecision}
                            volumePrecision={volumePrecision}
                            currencySymbol={currencySymbol}                    
                            marketDate={marketDate}
                            mark={data[x.qualityAttributeValueHash] ?? {markId: uuid(), eventId: uuid(), active: true}} 
                            qualityAttributeValues ={data.qualityAttributeValues} 
                            shortName={data["shortName"]}
                            //isNew={data[term.termId] ? false: true}
                        />
                    }

                )                
            )
         ];

         return columns;
    }

    joinMarks(aggregatedMarks, terms)
    {      
        const termIds = terms.map(x =>  x.termId);
        const filteredMarks = aggregatedMarks.filter(x => termIds.includes(x.termId));
        const { historicalReportingPeriodicity } = this.props;

        //Pivot the marks
        const groupedByMarketDate= filteredMarks.reduce((result, obj) => {
            const { qualityAttributeValueHash, marketDate, ...rest } = obj;

            if (!result[marketDate]) {
            result[marketDate] = {};
            result[marketDate]["marketDate"]= marketDate;
            }

            //Add a spot key so we don't mix marks from same instrument/different days
            const spotKey=qualityAttributeValueHash;
            result[marketDate][spotKey] = {...rest};
        
            return result;
        }, {});

        const pivotedMarks = Object.entries(groupedByMarketDate).map(([qualityAttributeValueHash, attributes]) => ({
             qualityAttributeValueHash,
             ...attributes
         }));

        const joinedData = joinArrays(terms, pivotedMarks,'marketDate');

        // Initialize an object to store sums and counts for each hash so we can calculate Averages
        let hashStats = {};

        // Iterate over each object in the array
        joinedData.forEach(obj => {
            // Iterate over each hash in the object
            Object.keys(obj).forEach(key => {
                // Check if the key is a hash (32 characters long)
                if (key.length === 64) {
                    // Initialize hash statistics if not already present
                    if (!hashStats[key]) {
                        hashStats[key] = { sum: 0, count: 0};
                    }
                    // Add the price to the sum and increment the count
                    if (obj[key].price !== null && !isNaN(obj[key].price)) {
                        hashStats[key].sum += obj[key].price;
                        hashStats[key].count++;
                    }
                }
            });
        });

        // Calculate the average for each hash
        let hashAverages = {marketDate: "Average", classProperty: "summary-row"};
        Object.keys(hashStats).forEach(key => {
            if( hashStats[key].count > 0)
            {
                hashAverages[key] = {price: hashStats[key].sum / hashStats[key].count};
            }
        });

        joinedData.push(hashAverages);

        return joinedData;
    }

    getCandleStickMarks()
    {
        const {candlestickMarksRaw, qualityAttributeValueHash} = this.state;        

        const candlestickMarks = candlestickMarksRaw.filter(x => x.qualityAttributeValueHash === (qualityAttributeValueHash ?? x.qualityAttributeValueHash));

        const yMin = candlestickMarksRaw.reduce((minValue, currentItem) => {
            return Math.min(minValue, currentItem.price ?? currentItem.price ?? currentItem.bid ?? currentItem.offer);
          }, Number.POSITIVE_INFINITY);


        const yMax = candlestickMarksRaw.reduce((maxValue, currentItem) => {
            return Math.max(maxValue, currentItem.price ?? currentItem.price ?? currentItem.bid ?? currentItem.offer);
          }, Number.NEGATIVE_INFINITY);

         return {
            candlestickMarks: candlestickMarks,
            yMax: yMax > 0  ? yMax * 1.05 : yMax * 0.95, //scale to within 20% for aesthetic reasons
            yMin: yMin > 0 ? yMin * 0.95 : yMin * 1.05 //scale to within 20% for aesthetic reasons
        };
    }



    render() { 
        const {sortColumn, isLoading, columns, marks, startDate, endDate} = this.state;

        const {yMin, yMax, candlestickMarks } = this.getCandleStickMarks();

        if (isLoading) return (<Loading/>);

        const {t, productId, currencySymbol, pricePrecision, historicalReportingPeriodicity } = this.props;

        return (
            <div className="container-fluid">
                <div className="row market-data-graph">
                    <CandlestickChart data={candlestickMarks ?? []} historicalReportingPeriodicity={historicalReportingPeriodicity} startDate={startDate} endDate={endDate} yMin={yMin} yMax={yMax} currencySymbol={currencySymbol} pricePrecision={pricePrecision}/>
                </div>
                <div className="row marks-table">
                    <Table className="table-marks" classProperty="classProperty" columns={columns} sortColumn = {sortColumn} data={marks} valueProperty="marketDate"/>
                </div>                                
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(HistoricalMarketDataTable)