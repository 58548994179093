import React, { Component } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getOrganizationSubscriptions } from "../../../common/services/marketdata/organizationSubscriptionService";
import SearchBox from "../../../common/components/search/searchBox";
import OrganizationSubscriptionsTable from "./organizationSubscriptionsTable";
import { withTranslation } from "react-i18next";

class OrganizationSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.handleOrganizationSubscriptionUpdated =
      this.handleOrganizationSubscriptionUpdated.bind(this);
  }

  state = {
    organizationSubscriptions: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {
    this.setState({
      organizationSubscriptions: await getOrganizationSubscriptions(),
      isLoading: false,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleOrganizationSubscriptionSelected = (
    organizationSubscription,
    isNew = false
  ) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(
      organizationSubscription,
      this.handleOrganizationSubscriptionUpdated,
      isNew
    );
  };

  handleOrganizationSubscriptionUpdated = (organizationSubscription) => {
    const organizationSubscriptions = [...this.state.organizationSubscriptions];
    const index = organizationSubscriptions.findIndex(
      (u) =>
        u.organizationSubscriptionId ===
        organizationSubscription.organizationSubscriptionId
    );
    if (index > -1) {
      organizationSubscriptions.splice(index, 1);
    }
    organizationSubscriptions.push(organizationSubscription);
    this.setState({
      organizationSubscriptions: organizationSubscriptions,
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      organizationSubscriptions: allOrganizationSubscriptions,
      activeOnly,
    } = this.state;

    let filtered = allOrganizationSubscriptions;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.organizationName != null &&
            u.organizationName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (u.subscribableName != null &&
            u.subscribableName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const organizationSubscriptions = paginate(sorted, currentPage, pageSize);

    return {
      totalCount: filtered.length,
      data: organizationSubscriptions,
    };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, user, onClick, allowEdit } = this.props;

    const { totalCount, data: organizationSubscriptions } = this.getPagedData();

    const newOrganizationSubscription = {
      organizationSubscriptionId: uuid(),
      name: t("New OrganizationSubscription"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() =>
                  this.handleOrganizationSubscriptionSelected(
                    newOrganizationSubscription,
                    true
                  )
                }
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddOrganizationSubscription")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedOrganizationSubscription", { count: totalCount })}</p>
        <OrganizationSubscriptionsTable
          organizationSubscriptions={organizationSubscriptions}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleOrganizationSubscriptionSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["auth"])(OrganizationSubscriptions);
