import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import ReviewTrades from "./reviewTrades";



const ReviewTradesAuthWrapper = () => {
  const { keycloak, initialized } = useKeycloak();
  const allowEdit = usePermitted(["api-approve-trades"]);
    const userAccountId =initialized && keycloak.tokenParsed.OriginatingUserAccountID;

  return (
    <ReviewTrades allowEdit={allowEdit} userAccountId={userAccountId}/>
  );
};

export default ReviewTradesAuthWrapper;


