import productQualityAttributePriceDrivers from '../../../admin/products/products/productQualityAttributePriceDrivers';
import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"productQualityAttribute/";

export async function getProductQualityAttributes() {
  const {data: ProductQualityAttributes} = await http.get(apiEndpoint);
  return ProductQualityAttributes;
}

export async function getProductQualityAttributeByQualityAttribute(qualityAttributeId) {
  const {data: ProductQualityAttributes} = await http.get(apiEndpoint+"ByAttribute/"+qualityAttributeId);
  return ProductQualityAttributes;
}

export async function getProductQualityAttributeCandidates(productId) {
  const {data: QualityAttributes} = await http.get(apiEndpoint+"Candidates/"+productId);
  return QualityAttributes;
}

export async function getProductQualityAttributesByProduct(productId) {
  const {data: ProductQualityAttributes} = await http.get(apiEndpoint+"ByProduct/"+productId);
  return ProductQualityAttributes;
}

export async function getPriceDriverQualityAttributes(productId) {
  const {data: ProductQualityAttributes} = await http.get(apiEndpoint+"GetPriceDriverAttributes/"+productId);
  return ProductQualityAttributes;
}

export async function getOtherProductQualityAttributes(productId) {
  const {data: ProductQualityAttributes} = await http.get(apiEndpoint+"GetOtherProductAttributes/"+productId);
  return ProductQualityAttributes;
}

export async function assignProductQualityAttribute(productQualityAttribute) {  
  const {data: ProductQualityAttribute} = await http.post(apiEndpoint, productQualityAttribute);
  return ProductQualityAttribute;
}

export async function updateProductQualityAttribute(productQualityAttribute) {
  const {data: ProductQualityAttribute} = await http.put(apiEndpoint, productQualityAttribute);
  return ProductQualityAttribute;
}

export async function revokeProductQualityAttribute(productId, qualityAttributeId) {
  return await http.delete(apiEndpoint+productId+"/"+qualityAttributeId);
}

export async function getProductQualityAttributeHistory(productQualityAttributeId) {
  const {data: ProductQualityAttributeHistory} = await http.get(apiEndpoint+"audit/"+productQualityAttributeId);
  return ProductQualityAttributeHistory;
}