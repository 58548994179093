import axios from'axios';
import {toast} from 'react-toastify';
import logger from './logService';

axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!expectedError){
      logger.log(error);
      toast.error('An unexpected error occurred:');
    }
    return Promise.reject(error);
  });

  function setJwt(jwt) {
    console.log("httpService Setting Bearer token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }
  
export default {
   get: axios.get,
   post: axios.post,
   put: axios.put,
   delete: axios.delete,
   setJwt
}