import React from 'react';
import FontAwesome from "react-fontawesome";
import { useTranslation} from 'react-i18next';

const Home = () => {
  const {t} = useTranslation();

  const handleEvent = (event, error) => {
    if (event ==='onReady')
    {
      window.location ='/';    
    } 
  }
  

 return (
   <div>
      <h1>{t("Maintenance")}</h1>
   </div>
 );
};

export default Home;