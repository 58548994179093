import React, { Component } from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { Tabs, Tab, FormCheck } from "react-bootstrap";
import {
  getUserAccount,
  addUserAccount,
  updateUserAccount,
  setPassword,
} from "../../../common/services/auth/userAccountService";
import Form from "../../../common/components/form/form";
import ProtectedFieldset from "../../../common/components/form/protectedFieldset";
import Loading from "../../../common/components/loading/loading";
import UserAccountHistory from "./userAccountHistory";
import UserAccountOrganizations from "./userAccountOrganizations";
import UserAccountRoles from "./userAccountRoles";
import { withTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';


class UserAccount extends Form {
  state = {
    data: {
      userAccountId: "",
      login: "",
      firstName: "",
      lastName: "",
      email: "",
      active: true,
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    errors: {},
    isLoading: true,
    isNew: true,
    showModal: false,
  };

  schema = {
    userAccountId: Joi.string(),
    login: Joi.string().required().label(this.props.t("Login")),
    firstName: Joi.string().label(this.props.t("FirstName")),
    lastName: Joi.string().label(this.props.t("LastName")),
    email: Joi.string().email().required().label(this.props.t("Email")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { userAccountId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var userAccount = {};

    if (!isNew) {
      userAccount = await getUserAccount(userAccountId);
      userAccount.eventId = uuid();
    } //New User Add
    else {
      userAccount = {
        userAccountId: userAccountId,
        login: "",
        firstName: "",
        lastName: "",
        email: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({ data: this.mapToViewModel(userAccount), isLoading: false });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(userAccount.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(userAccount.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });
    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(userAccount) {
    return {
      userAccountId: userAccount.userAccountId,
      login: userAccount.login,
      firstName: userAccount.firstName,
      lastName: userAccount.lastName,
      email: userAccount.email,
      active: userAccount.active,
      eventId: userAccount.eventId,
    };
  }

  handleSetPassword = async () => {
    const { t } = this.props;

    try 
    {
      const { data }  = this.state;

      const response = await setPassword(data.userAccountId);
      toast.success(t("SetPasswordSent"));

    }
    catch (ex)
    {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Login = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
    finally
    {
      this.handleModalClose()
    }
  };

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });

      if (!isNew) {
        const response = await updateUserAccount(this.state.data);
        toast.success(t("UserUpdated"));
      } else {
        const response = await addUserAccount(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.userAccountId, this.state.data.login);
        toast.success(t("UserAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(this.state.data);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Login = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { userAccount, isLoading, isNew, showModal, createdDate, updatedDate } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, userAccountId, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <Modal show={showModal} size="sm" onHide={this.handleModalClose}
               backdrop="static"
              keyboard={false}>
           <Modal.Body className="bg-dark text-light" >
            <div className="row" style={{textAlign: "center"}}>
              <p>{t("ConfirmSetPassword")}</p>
            </div>             
            <div className="row">
            <div className="col"></div>
              <div className="col" style={{textAlign: "center"}}>
                <button className="btn btn-sm btn-secondary" onClick={this.handleModalClose}>
                    {t("Cancel")}
                </button>
              </div>
              <div className="col" style={{textAlign: "center"}}>
                <button className="btn btn-sm btn-success" onClick={this.handleSetPassword}>
                  {t("Confirm")}
                </button>
              </div>
              <div className="col"></div>
            </div>

          </Modal.Body>
        </Modal>
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              <div className="row">                
                <div className="col">
                  {this.renderSwitch("active", t("Active"))}                
                </div>
                <div className="col">
                  {!isNew &&
                    <button className="btn btn-sm btn-success" style={{ float: "right"}} onClick={(e) => { e.preventDefault(); this.setState({showModal: true});}}>                       
                      Set Password
                    </button>
                  }
                </div>
              </div>
              {isNew
                ? this.renderInput("login", t("Login"))
                : this.renderDisabledInput("login", "Login")}
              {this.renderInput("firstName", t("FirstName"))}
              {this.renderInput("lastName", t("LastName"))}
              {this.renderInput("email", t("Email"))}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("Organizations")}>
                <UserAccountOrganizations
                  userAccountId={userAccountId}
                  allowEdit={allowEdit}
                />
              </Tab>
              <Tab eventKey={2} title={t("Roles")}>
                <UserAccountRoles
                  userAccountId={userAccountId}
                  allowEdit={allowEdit}
                />
              </Tab>
              <Tab eventKey={3} title={t("History")}>
                <UserAccountHistory userAccountId={userAccountId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["auth"])(UserAccount);
