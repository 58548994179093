import React, { Component } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../common/components/table/pagination";
import { getStatusChanges } from "../../common/services/albertaEnvironmental/albertaEnvironmentalService";
import SearchBox from "../../common/components/search/searchBox";
import { formatDate } from "../../common/services/utilities";
import { withTranslation } from "react-i18next";
import AlbertaEnvironmentalStatusChangesTable from "./albertaEnvironmentalStatusChangesTable";
import Input from "../../common/components/form/input";
import Select from "../../common/components/form/select";
import * as XLSX from 'xlsx';
import FontAwesome from "react-fontawesome";
import StatusChangesChart from './tradesChart';
//import Multiselect from "react-bootstrap-multiselect";
//import Multiselect, {components} from 'react-select';
import Multiselect, {components} from "../../common/components/form/multiSelect";
class AlbertaEnvironmentalStatusChanges extends Component {

  state = {
    statusChanges: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    aeor: true,
    epc: true,
    startDate: null,
    endDate: null,
    minDate: null,
    maxDate: null,
    quantificationProtocols: [],
    quantificationProtocol: "All",
    statuses: [],
    selectedOldStatuses: [],    
    selectedNewStatuses: [],
    sortColumn: { path: "TransactionId", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    //this.setState({ statusChanges: await getStatusChanges(), isLoading: false });
    const statusChanges = await getStatusChanges();
    
    var date = new Date();
    const endDate= date.toLocaleDateString('fr-CA');
    const maxDate= date.toLocaleDateString('fr-CA');
    const startDate= (new Date(new Date().setDate(date.getDate() - 30))).toLocaleDateString('fr-CA');;
    const minDate=(new Date(Math.min.apply(null, statusChanges.map(x => new Date(x.TransactionDate))))).toLocaleDateString('fr-CA');

    const quantificationProtocols = [{quantificationProtocol: "All"}].concat([ ...new Set(statusChanges.filter(x => x.QuantificationProtocol).map(x => x.QuantificationProtocol))].sort().map(x => ({quantificationProtocol: x})));

    const statuses = [ ...new Set(
        statusChanges.filter(x => x.OldEmissionOffsetStatus).map(x => x.OldEmissionOffsetStatus)
        .concat(statusChanges.filter(x => x.NewEmissionOffsetStatus).map(x => x.NewEmissionOffsetStatus))
    )]
    .sort()
    .map(x => ({value: x, label: x}));

    //quantificationProtocols.push({quantificationProtocol: "All"});
    this.setState({
      startDate: startDate,
      endDate: endDate,
      minDate: minDate,
      maxDate: maxDate, 
      statusChanges: statusChanges,
      isLoading: false,
      quantificationProtocols: quantificationProtocols,
      statuses: statuses,
      selectedOldStatuses: statuses,    
      selectedNewStatuses: statuses,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleStartDateChange = (startDate) => {
    if(!isNaN(Date.parse(startDate)))
    {
      this.setState({ startDate: startDate, currentPage: 1  });
    }
  };

  handleEndDateChange = (endDate) => {
    if(!isNaN(Date.parse(endDate)))
    {
      this.setState({ endDate: endDate, currentPage: 1  });
    }
  };

  handleQuantificationProtocolChange = (quantificationProtocol) => {
    this.setState({ quantificationProtocol: quantificationProtocol, currentPage: 1  });
  };

  handleToggleAeor = () => {
    this.setState({ aeor: !this.state.aeor, currentPage: 1  });
  };

  handleToggleEpc = () => {
    this.setState({ epc: !this.state.epc, currentPage: 1  });
  };

  handleOldStatusesChange = (statuses) => {
    this.setState({ selectedOldStatuses: statuses, currentPage: 1  });
  };

  handleNewStatusesChange = (statuses) => {
    this.setState({ selectedNewStatuses: statuses, currentPage: 1  });
  };

  downloadExcel = (data) => {
    const excelData = data.map((trade) => (
      {
          TransactionId: trade.TransactionId,
          TransactionDate: formatDate(trade.TransactionDate),
          Buyer: trade.Buyer,
          Seller: trade.Seller,
          Quantity: trade.Quantity,
          Registry: trade.Registry,
          Vintage: trade.Vintage,
          QuantificationProtocol: trade.QuantificationProtocol,
          SerialStart: trade.SerialStart,
          SerialEnd: trade.SerialEnd,
      }
  ));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "AlbertaEnvironmentalStatusChanges.xlsx");
  };

  getFilteredData = () => {
    const {
      searchQuery,
      sortColumn,
      epc,
      aeor,
      startDate,
      endDate,
      quantificationProtocol,
      selectedOldStatuses,
      selectedNewStatuses,
      statusChanges: allStatusChanges,
    } = this.state;

    let filtered = allStatusChanges;
    if (!epc) filtered = filtered.filter((u) => u.Registry != "Emission Performance Credit");
    if (!aeor) filtered = filtered.filter((u) => u.Registry != "AEOR");

    filtered = filtered.filter(
      (u) =>
        new Date(u.TransactionDate) >= new Date(startDate+"T00:00:00")
          && new Date(u.TransactionDate) <= new Date(endDate+"T00:00:00")
    );

    if(quantificationProtocol !== "All")
    {
      filtered = filtered.filter(
        (u) => u.QuantificationProtocol === quantificationProtocol ?? u.QuantificationProtocol
      )
    }

    filtered = filtered.filter(
      (u) => selectedOldStatuses.map(x => x.value).includes(u.OldEmissionOffsetStatus)
    )

    filtered = filtered.filter(
      (u) => selectedNewStatuses.map(x => x.value).includes(u.NewEmissionOffsetStatus)
    )

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (
            u.Buyer !== null &&          
            u.Buyer.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.Seller !== null &&
            u.Seller.toLowerCase().includes(searchQuery.toLowerCase())
          )
          ||
          (
            u.SerialStart !== null &&
            u.SerialStart.split("-", 2).join("-").toLowerCase().includes(searchQuery.toLowerCase())
          )          
      );

      const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

      return { data: sorted };
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
    } = this.state;

    const {data} = this.getFilteredData();
    const statusChanges = paginate(data, currentPage, pageSize);

    return { totalCount: data.length, data: statusChanges, allData: data };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      aeor,
      epc,
      startDate,
      endDate,
      minDate,
      maxDate,
      quantificationProtocols,
      quantificationProtocol,
      statuses,
      selectedNewStatuses,
      selectedOldStatuses,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, user, onClick, allowEdit } = this.props;

    const { totalCount, data: statusChanges, allData: allStatusChanges } = this.getPagedData();

    return (
      <div className="container-fluid">
        <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-center"><h1>{t("AlbertaEnvironmentalStatusChanges")}</h1></div>
          <div className="col-md-3" style={{alignSelf:"flex-end"}}>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col-md-2">
            <div className="row">
              <Input                    
                  data-bs-theme="dark" 
                  type="date"
                  min={minDate} 
                  max={endDate} 
                  onKeyDown={(e) => e.preventDefault()}
                  onFocus={(e) => e.blur()}                  
                  onChange={e => this.handleStartDateChange(e.currentTarget.value)}
                  //name = "name"
                  value = {startDate}
                  label = {t("StartDate")}
              />       
              <Input                    
                  data-bs-theme="dark" 
                  type="date"
                  min={startDate} 
                  max={maxDate} 
                  onKeyDown={(e) => e.preventDefault()}
                  onFocus={(e) => e.blur()}                  
                  onChange={e => this.handleEndDateChange(e.currentTarget.value)}
                  //name = "name"
                  value = {endDate}
                  label = {t("EndDate")}
              />      
            </div>
         
          </div>
          <div className="col-md-1" style={{alignSelf:"flex-end"}}>
            <Form.Switch
              className="switch"
              type="switch"
              label={t("Aeor")}
              checked={aeor}
              value={aeor}
              onChange={this.handleToggleAeor}
            />
          </div>
          <div className="col-md-1" style={{alignSelf:"flex-end"}}>
            <Form.Switch
              className="switch"
              type="switch"
              label={t("Epc")}
              checked={epc}
              value={epc}
              onChange={this.handleToggleEpc}
            />
          </div>       
          <div className='col-md-2' style={{alignSelf:"flex-end"}}>
            <Select
                data-bs-theme="dark" 
                idField = "quantificationProtocol"
                optionField = "quantificationProtocol"
                name = "quantificationProtocol"
                //disabled={Object.keys(organizations).length < 2}
                value = {quantificationProtocol}
                label = {t("QuantificationProtocol")}
                options={quantificationProtocols}
                onChange={e => this.handleQuantificationProtocolChange(e.currentTarget.value)}
            />        
          </div>   
          <div className='col-md-2'>
            <div className='row'>
              <Multiselect 
                isMulti
                hideSelectedOptions={false}    
                options = {statuses}
                value = {selectedOldStatuses}
                  label = {t("OldStatuses")}
                  className="neutral-react-select-container"
                  classNamePrefix="neutral-react-select"                              
                  onChange = {e => this.handleOldStatusesChange(e)}
              />
              <Multiselect 
                isMulti
                hideSelectedOptions={false}    
                options = {statuses}
                value = {selectedNewStatuses}
                  label = {t("NewStatuses")}
                  className="neutral-react-select-container"
                  classNamePrefix="neutral-react-select"                              
                  onChange = {e => this.handleNewStatusesChange(e)}
              />
            </div>
          </div>               
          <div className='col-md-1'  style={{alignSelf:"flex-end"}}>    
            <button className="btn btn-sm btn-secondary" onClick={()=>this.downloadExcel(allStatusChanges)}>
              <FontAwesome  name="download"/>
              {" "}
              {t("Excel")}
            </button>
          </div>           
        </div>
        <p>{t("RetrievedAlbertaEnvironmentalStatusChanges", { count: totalCount })}</p>
        <div className="col-md-12 market-data-tile">
            <StatusChangesChart 
                data={allStatusChanges} 
                title="Status Change Quantities by Day" 
                startDate={startDate}
                endDate={endDate}
                // uniqueStatusValues={uniqueEPCStatusValues} 
                // colorScheme={EPCColorScheme} 
                // vintages={EPCVintages}                
            />
            </div>              
        <AlbertaEnvironmentalStatusChangesTable
          statusChanges={statusChanges}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          //onRowClick={this.handleStatus ChangeSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalStatusChanges);
