import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import MarketMarks from "./marketMarks";
import MarketSelector from "../marketSelector";
import { withTranslation } from "react-i18next";

class Marks extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 15,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Markets"),
              enableClose: false,
              component: "MarketSelector",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 85,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleMarkSelect = (market) => {
    const { productId, marketId, name } = market;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "MarketMarks",
            name: `${name}`,
            id: `${marketId}`,
            config: { productId, market },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(marketId));
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const MarkId = node.getId();

    if (componentId === "MarketSelector") {
      return (
        <MarketSelector
          onClick={this.handleMarkSelect}
          userAccountId={this.props.userAccountId}
          userOrganizations={this.props.userOrganizations}
          allowEdit={this.props.allowEdit}
          bypassSubscriptions={this.props.allowEditOnBehalf}
          contributeOnly={true}
          marketsWithAttributes={this.props.marketsWithAttributes} 
          marketGroupMarkets={this.props.marketGroupMarkets}
          products={this.props.products}
          userAccountSubscriptionsByUserAccount={this.props.userAccountSubscriptionsByUserAccount}          
        />
      );
    } else if (componentId === "MarketMarks") {
      const { productId, market } = node.getConfig();

      return (
        <MarketMarks
          productId={productId}
          market={market}
          userAccountId={this.props.userAccountId}
          userOrganizations={this.props.userOrganizations}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
          allowEditOnBehalf={this.props.allowEditOnBehalf}
          allowOverrideMarks={this.props.allowOverrideMarks}
          allowGenerateMarks={this.props.allowGenerateMarks}
          allowCopyMarks={this.props.allowCopyMarks}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["auth"])(Marks);
