import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import Marks from "./submitMarks";

const SubmitMarksAuthWrapper = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const allowEdit = usePermitted(["api-manage-marks"]);
  const allowEditOnBehalf = usePermitted(["api-approve-marks"]);
  const userAccountId =
    initialized && keycloak.tokenParsed.OriginatingUserAccountID;
  const userOrganizations = initialized && keycloak.tokenParsed.group;
  const allowOverrideMarks = usePermitted(["api-approve-marks"]);
  const allowGenerateMarks = usePermitted(["api-generate-marks"]);
  const allowCopyMarks = usePermitted(["api-copy-marks"]);

  return (
    <Marks
      allowEdit={allowEdit}
      userAccountId={userAccountId}
      userOrganizations={userOrganizations}
      allowEditOnBehalf={allowEditOnBehalf}
      allowOverrideMarks={allowOverrideMarks}
      allowGenerateMarks={allowGenerateMarks}
      allowCopyMarks={allowCopyMarks}
      marketsWithAttributes={props.marketsWithAttributes}       
      marketGroupMarkets={props.marketGroupMarkets}
      products={props.products}
      venues={props.venues}
      termSchedules={props.termSchedules}
      userAccountSubscriptionsByUserAccount={props.userAccountSubscriptionsByUserAccount}      
    />
  );
};

export default SubmitMarksAuthWrapper;
