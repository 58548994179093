import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import SearchBox from "../../common/components/search/searchBox";
import ReviewMarksTable from "./reviewMarksTable";
import { withTranslation } from "react-i18next";
import { MarkState } from "../../common/enumerations/enums";

class ReviewMarksTableHeader extends Component {
  state = {
    pageSize: 20,
    searchQuery: "",
    submittedOnly: false,
    sortColumn: { path: "updatedDate", order: "desc" },
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleSubmittedOnly = () => {
    this.setState({ submittedOnly: !this.state.submittedOnly });
  };

  getFilteredData = () => {
    const { sortColumn, searchQuery, submittedOnly } = this.state;

    const { marks: allMarks } = this.props;

    let filtered = allMarks;

    if (submittedOnly)
      filtered = filtered.filter((u) => u.markState === MarkState.Submitted);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.organizationName != null &&
            u.organizationName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (u.termName != null &&
            u.termName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (u.priceBasisName != null &&
            u.priceBasisName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (u.qualityAttributeValues.length > 0 &&
            u.qualityAttributeValues.filter((q) =>
              q.value.toLowerCase().includes(searchQuery.toLowerCase())
            ).length)
      );

    //Add the qualityAttributeVolumes so we can sort properly.
    filtered = filtered.map((f) => {
      return {
        ...f,
        ...f.qualityAttributeValues.reduce((acc, attribute) => {
          acc[attribute.qualityAttributeId] = attribute.value;
          return acc;
        }, {}),
      };
    });

    const marks = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    return { totalCount: filtered.length, marks };
  };

  approveAll = (marks) =>
    this.props.handleApprove(
      marks
        .filter((m) => m.markState === MarkState.Submitted)
        .map((m) => m.markId)
    );

  rejectAll = (marks) =>
    this.props.handleReject(
      marks
        .filter((m) => m.markState === MarkState.Submitted)
        .map((m) => m.markId)
    );

  render() {
    const { searchQuery, sortColumn, activeOnly, isLoading } = this.state;

    //if (isLoading) return <Loading />;

    const { t, canApproveMarks } = this.props;

    const { totalCount, marks } = this.getFilteredData();

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("SubmittedOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleSubmittedOnly}
            />
          </div>
          <div className="col" style={{ textAlign: "end" }}>
            {canApproveMarks && (
              <button
                onClick={() => this.rejectAll(marks)}
                className="btn btn-sm btn-danger float-sm-right"
              >
                {t("RejectAll")}
              </button>
            )}
            {canApproveMarks && (
              <button
                onClick={() => this.approveAll(marks)}
                className="btn btn-sm btn-success float-sm-right"
                style={{ marginLeft: "15px" }}
              >
                {t("ApproveAll")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedMarks", { count: totalCount })}</p>
        <ReviewMarksTable
          marks={marks}
          handleApprove={this.props.handleApprove}
          handleReject={this.props.handleReject}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          canApproveMarks={canApproveMarks}
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(ReviewMarksTableHeader);
