import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import {aggregateMarksByDate} from "../../services/marketdata/marketDataUtilities";

const LineChart = ({ data, forwardReportingPeriodicity, startDate, endDate, yMin, yMax, currencySymbol, pricePrecision }) => {
  const svgRef = useRef();
  const tooltipRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });


  useEffect(() => {

     const svg = d3.select(svgRef.current);
     svg.selectAll('*').remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;

    const margin = { top: 20, right: 20, bottom: 45, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    setDimensions({ width: parentWidth, height: parentHeight }); // Update the dimensions


    const chartSvg = svg
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${parentWidth} ${parentHeight}`)
      .attr('preserveAspectRatio', 'none') // Preserve aspect ratio
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);    

      let startMonth = new Date(startDate);
      startMonth.setMonth(startMonth.getMonth());
      startMonth.setDate(1);

      let startYear = new Date(startDate);
      startYear.setMonth(1);
      startYear.setDate(1);

      const dateSequence = (() =>
      {
        switch (forwardReportingPeriodicity)
        {
          case "Hour":
            return d3.timeHours(
              d3.timeDay.floor(startDate) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
              , d3.timeDay.ceil(endDate)
            );
          case "Day":
            return d3.timeDays(
              d3.timeDay.offset(new Date(startDate), -1) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
              , d3.timeDay.ceil(new Date(endDate))
              );
            case "Week":
              return d3.timeWeeks(
                d3.timeDay.offset(new Date(startDate), -1) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
                , d3.timeDay.ceil(new Date(endDate))
                );
            case "Month":
              return d3.timeMonths(
                d3.timeDay.offset(new Date(startMonth), -1) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
                , d3.timeDay.ceil(new Date(endDate))
                );
            case "Year":
              return d3.timeYears(
                d3.timeDay.offset(new Date(startYear), -1) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
                , d3.timeDay.ceil(new Date(endDate))
                );
          default:
            return d3.timeMonths(
              d3.timeDay.offset(new Date(startDate), -1) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
              , d3.timeDay.ceil(new Date(endDate))
            );
        };
      })();

    // Create scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(dateSequence))
      .range([0, innerWidth])
      //.padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([yMin ?? Math.min(0, d3.min(data, (d) => d.low ?? d.price)), yMax ?? d3.max(data, (d) => d.high ?? d.price)]) //Stopgap in case yMax/yMin not provided
      .range([innerHeight, 0]);

    //rollUp the data to get the averages against which a best fit
    const aggregatedMarks = aggregateMarksByDate(data);

    const runningAggregate = aggregatedMarks.reduce((result, dataPoint) => {
      const date = dataPoint.date;
      const existingData = result.find((item) => new Date(item.date).toISOString() === new Date(date).toISOString());
    
      if (!existingData) {
        result.push({
          date,
          priceSum: dataPoint.price,
          count: dataPoint.price && 1           
      });
      } else {
        existingData.priceSum += dataPoint.price;
        existingData.count += dataPoint.price && 1;
      }
    
      return result;
    }, []);
      
    // Calculate true averages
    const aggregatedData = runningAggregate.map((item) => ({
      date: item.date,
      price: item.priceSum / item.count,
    }));
    

  // Calculate moving average
  const movingAverageWindow = 1; // Adjust the window size as needed
  const sortedData = [...aggregatedData].sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort the data

  const dataWithMovingAverage = sortedData.map((d, i, arr) => {
    const start = Math.max(0, i - movingAverageWindow + 1);
    const end = i + 1;
    const subset = arr.slice(start, end);
    const sum = subset.reduce((acc, val) => acc + val.price, 0);
    const movingAverage = sum / subset.length;
    return { ...d, movingAverage };
  });

    // Add moving average line
    const movingAverageLine = d3.line()
    .defined(d => !isNaN(d.movingAverage)) // Ignore undefined or NaN values
    .x(d => xScale(new Date(d.date)))
    .y(d => yScale(d.movingAverage))
    .curve(d3.curveBasis);

    chartSvg.selectAll('.moving-average-line').remove(); // Remove existing lines
    chartSvg.append('path')
      .data([dataWithMovingAverage])
      .attr('class', 'moving-average-line')
      .attr('d', movingAverageLine)
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-width', 3)

      // Create a tooltip element
      const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("border-radius", "5px")
      .style("color", "white")
      .style("pointer-events", "none")
      .style("opacity", 0);

      tooltipRef.current = tooltip;

    // Draw circles for each price
    const circleGroup = chartSvg.append('g').attr('class', 'price-circles');

    circleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.price !== 'undefined' && d.price !== null  && d.markState === "Approved"))) //must be a price to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'price-circle')
      .attr('cx', (d) => xScale(new Date(d.date)))
      .attr('cy', (d) => yScale(d.price))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'grey')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong></strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });            

    // Draw circles for each price
    const submittedCircleGroup = chartSvg.append('g').attr('class', 'price-circles');

    submittedCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.price !== 'undefined' && d.price !== null  && d.markState === "Submitted"))) //must be a price to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'price-circle')
      .attr('cx', (d) => xScale(new Date(d.date)))
      .attr('cy', (d) => yScale(d.price))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'goldenrod')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong></strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });               

    // Draw circles for each bid
    const bidCircleGroup = chartSvg.append('g').attr('class', 'bid-circles');

    bidCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.bid !== 'undefined' && d.bid !== null &&d.bid))) //must be a bid to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'bid-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.bid))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'blue')
      .attr('opacity', '75%')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong>Bid:</strong> ${currencySymbol}${d.bid.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });           

    // Draw circles for each offer
    const offerCircleGroup = chartSvg.append('g').attr('class', 'offer-circles');

    offerCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.offer !== 'undefined' && d.offer !== null &&d.offer))) //must be a offer to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'offer-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.offer))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'red')
      .attr('opacity', '50%')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong>Offer:</strong> ${currencySymbol}${d.offer.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });                 


      const tickFormat = (() =>
      {
        switch (forwardReportingPeriodicity)
        {
          case "Hour":
            return '%a %H:%M';
          case "Day":
            return '%b-%d';
          case "Week":
            return '%Y-%V';
          case "Month":
            return '%b-%y';
          case "Year":
            return '%Y';            
          default:
            return '%b-%d';
        };
      })();

    // Draw axes
    const priceFormat = (currencySymbol??'$')+',.'+(pricePrecision??'2')+'f';
    const xAxis = d3.axisBottom(xScale)
      .tickFormat(d3.timeFormat(tickFormat))

    const yAxis = d3.axisLeft(yScale)
      .tickFormat(d3.format(priceFormat));

    chartSvg
      .append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end') // Set the text-anchor property to 'end' to align the text at the end of the tick
      .attr('transform', 'rotate(-45)'); // Rotate the text by -45 degrees

    chartSvg
      .append('g')
      .call(yAxis)
      .selectAll('text')
      .attr('dy', '-0.5em') // Adjust the vertical position of the labels
      .style('text-anchor', 'end'); // Align the text to the end of the tick


    // Y-axis label
    chartSvg
    .append('text')
    .attr('x', 0 - innerHeight / 2)
    .attr('y', -margin.left)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text('Price'); // Customize the label as needed

    return () => {
      // Remove the tooltip when the component unmounts
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
    };

  }, [data]);

  return (
    <svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
      {/* Chart will be drawn here */}
    </svg>
  );
};

export default LineChart;