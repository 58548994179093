import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import ProductAdmin from "./products/productAdmin";
import AliasAdmin from "./aliases/aliasAdmin";
import QualityAttributeAdmin from "./qualityAttributes/qualityAttributeAdmin";
import QualityAttributeValueAdmin from "./qualityAttributeValues/qualityAttributeValueAdmin";
import CommodityAdmin from "./commodities/commodityAdmin";
import CommodityClassAdmin from "./commodityClasses/commodityClassAdmin";
import { useTranslation } from "react-i18next";

const AuthAdmin = () => {
  const queryProducts = usePermitted(["api-query-products"]);
  const manageProducts = usePermitted(["api-manage-products"]);
  const queryCommodities = usePermitted(["api-query-commodities"]);
  const manageCommodities = usePermitted(["api-manage-commodities"]);
  const queryCommodityClasses = usePermitted(["api-query-commodity-classes"]);
  const manageCommodityClasses = usePermitted(["api-manage-commodity-classes"]);
  const queryAliases = usePermitted(["api-query-aliases"]);
  const manageAliases = usePermitted(["api-manage-aliases"]);
  const queryQualityAttributes = usePermitted(["api-query-quality-attributes"]);
  const manageQualityAttributes = usePermitted(["api-manage-quality-attributes"]);
  const queryQualityAttributeValues = usePermitted(["api-query-quality-attribute-values"]);
  const manageQualityAttributeValues = usePermitted(["api-manage-quality-attribute-values"]);
  const queryQualityAttributeCommodities = usePermitted([
    "api-query-quality-attribute-commodities",
  ]);
  const manageQualityAttributeCommodities = usePermitted([
    "api-manage-quality-attribute-commodities",
  ]);
  const queryProductQualityAttributes = usePermitted(["api-query-product-quality-attributes"]);
  const manageProductQualityAttributes = usePermitted([
    "api-manage-product-quality-attributes",
  ]);
  const { t } = useTranslation(["products"]);

  return (
    <Tabs
      className="nav-dark"
      defaultActiveKey={1}
      id="admin-tabs"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {queryProducts && (
        <Tab eventKey={1} title={t("Products")}>
          <ProductAdmin allowEdit={manageProducts} />
        </Tab>
      )}
      {queryCommodities && (
        <Tab eventKey={2} title={t("Commodities")}>
          <CommodityAdmin allowEdit={manageCommodities} />
        </Tab>
      )}
      {queryCommodityClasses && (
        <Tab eventKey={3} title={t("CommodityClasses")}>
          <CommodityClassAdmin allowEdit={manageCommodityClasses} />
        </Tab>
      )}
      {queryAliases && (
        <Tab eventKey={4} title={t("Aliases")}>
          <AliasAdmin allowEdit={manageAliases} />
        </Tab>
      )}
      {queryQualityAttributes && (
        <Tab eventKey={5} title={t("QualityAttributes")}>
          <QualityAttributeAdmin allowEdit={manageQualityAttributes} />
        </Tab>
      )}
      {queryQualityAttributeValues && (
        <Tab eventKey={6} title={t("QualityAttributeValues")}>
          <QualityAttributeValueAdmin allowEdit={manageQualityAttributeValues} />
        </Tab>
      )}
    </Tabs>
  );
};

export default AuthAdmin;

// class AuthAdmin extends Component {

//     render() {
//         return (

//         );
//     }
// }

// export default AuthAdmin;
