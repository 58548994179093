import React, { Component } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getMarkets } from "../../../common/services/markets/marketService";
import SearchBox from "../../../common/components/search/searchBox";
import MarketsTable from "./marketsTable";
import { withTranslation } from "react-i18next";

class Markets extends Component {
  constructor(props) {
    super(props);

    this.handleMarketUpdated = this.handleMarketUpdated.bind(this);
  }

  state = {
    markets: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {
    this.setState({ markets: await getMarkets(), isLoading: false });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleMarketSelected = (market, isNew = false) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(market, this.handleMarketUpdated, isNew);
  };

  handleMarketUpdated = (market) => {
    const markets = [...this.state.markets];
    const index = markets.findIndex((u) => u.marketId === market.marketId);
    if (index > -1) {
      markets.splice(index, 1);
    }
    markets.push(market);
    this.setState({ markets: markets });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      markets: allMarkets,
      activeOnly,
    } = this.state;

    let filtered = allMarkets;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          u.name != null &&
          u.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const markets = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: markets };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, user, onClick, allowEdit } = this.props;

    const { totalCount, data: markets } = this.getPagedData();

    const newMarket = {
      marketId: uuid(),
      name: t("NewMarket"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() => this.handleMarketSelected(newMarket, true)}
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddMarket")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedMarket", { count: totalCount })}</p>
        <MarketsTable
          markets={markets}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleMarketSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(Markets);
