import React, { Component } from 'react';
import _ from 'lodash';

class TableBody extends Component {
    renderCell = (item, column) => {
        if (column.content) return column.content(item);

        return _.get(item, column.path);
    }

    createKey (item, column)
    {
        return item[this.props.valueProperty]+ (column.path || column.key);
    }


    render() { 
        const {data, columns, classProperty, valueProperty, onRowClick, onMouseEnter, onMouseLeave} = this.props;
            return (
            <tbody>
                {data && data.map((item, index) => <tr className={(onRowClick ? "clickable" : "") + (item[classProperty] ? (" " +item[classProperty]) : "")} onClick={onRowClick ? () => onRowClick(item) : undefined} onMouseEnter={onMouseEnter ? () => onMouseEnter(item) : undefined} onMouseLeave={onMouseLeave ? () => onMouseLeave(item) : undefined}   key = {item[valueProperty]}>
                   {columns.map(column => <td className={column.className} key={this.createKey(item, column)}>{this.renderCell(item, column)}</td>)} 
                </tr>
                )}
            </tbody>
            );
    }
}

 
TableBody.defaultProps = {
    valueProperty: "_id"
}

 
export default TableBody;