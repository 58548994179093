import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import MarketAdmin from "./markets/marketAdmin";
import CurrencyAdmin from "./currencies/currencyAdmin";
import UomAdmin from "./uoms/uomAdmin";
import VenueAdmin from "./venues/venueAdmin";
import PriceBasisAdmin from "./priceBases/priceBasisAdmin";
import TermAdmin from "./terms/termAdmin";
import { useTranslation } from "react-i18next";

const AuthAdmin = () => {
  const queryMarkets = usePermitted(["api-query-markets"]);
  const manageMarkets = usePermitted(["api-manage-markets"]);
  const queryCurrencies = usePermitted(["api-query-currencies"]);
  const manageCurrencies = usePermitted(["api-manage-currencies"]);
  const queryUoms = usePermitted(["api-query-uoms"]);
  const manageUoms = usePermitted(["api-manage-uoms"]);
  const queryVenues = usePermitted(["api-query-venues"]);
  const manageVenues = usePermitted(["api-manage-venues"]);
  const queryPriceBases = usePermitted(["api-query-price-bases"]);
  const managePriceBases = usePermitted(["api-manage-price-bases"]);
  const queryTerms = usePermitted(["api-query-terms"]);
  const manageTerms = usePermitted(["api-manage-terms"]);
  const { t } = useTranslation(["markets"]);

  return (
    <Tabs
      className="nav-dark"
      defaultActiveKey={1}
      id="admin-tabs"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {queryMarkets && (
        <Tab eventKey={1} title={t("Markets")}>
          <MarketAdmin allowEdit={manageMarkets} />
        </Tab>
      )}
      {queryCurrencies && (
        <Tab eventKey={2} title={t("Currencies")}>
          <CurrencyAdmin allowEdit={manageCurrencies} />
        </Tab>
      )}
      {queryPriceBases && (
        <Tab eventKey={3} title={t("PriceBasis")}>
          <PriceBasisAdmin allowEdit={managePriceBases} />
        </Tab>
      )}
      {queryTerms && (
        <Tab eventKey={4} title={t("Terms")}>
          <TermAdmin allowEdit={manageTerms} />
        </Tab>
      )}
      {queryUoms && (
        <Tab eventKey={5} title={t("Uoms")}>
          <UomAdmin allowEdit={manageUoms} />
        </Tab>
      )}
      {queryVenues && (
        <Tab eventKey={6} title={t("Venues")}>
          <VenueAdmin allowEdit={manageVenues} />
        </Tab>
      )}
    </Tabs>
  );
};

export default AuthAdmin;

// class AuthAdmin extends Component {

//     render() {
//         return (

//         );
//     }
// }

// export default AuthAdmin;
